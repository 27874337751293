import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from './DropDown';
import "./CustomSelect.scss";
import { SelectOptionDiv, SelectOptionDiv2 } from '../styledComponent';
import dropdown from '../../../assets/images/nav-svg/down arrow.svg';
import { ThemeContext } from '../../../context';

const CustomSelect = ({
  options,
  onSelect,
  width,
  topText,
  allStyles,
  timer,
  session,
  handleDDSession,
  dispCNS,
  sessionData,
  setSessionData,
  isOpen,
  setIsOpen,
  selectedOption,
  setSelectedOption,
  handleOptionClick,
}) => {
  const [sessionId, setSessionId] = useState('');
  const { themeName } = useContext(ThemeContext);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  return (
    <div className="select-container" ref={dropdownRef} id="create-new-session">
      {timer ? (
        <SelectOptionDiv2 theme={allStyles} className={`select ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
          <div style={{ color: `${themeName === 'light' ? 'black' : 'white'}`, fontSize: "1rem" }}>
            {selectedOption ? (selectedOption.name.length > 10 ? `${selectedOption.name.slice(0, 10)}...` : selectedOption.name) : topText}
          </div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.6918 3.81886C14.4945 3.63604 14.2268 3.53333 13.9477 3.53333C13.6686 3.53333 13.401 3.63604 13.2036 3.81886L7.99365 8.64608L2.78371 3.81886C2.5852 3.64122 2.31933 3.54292 2.04337 3.54515C1.7674 3.54737 1.50342 3.64993 1.30827 3.83074C1.11313 4.01155 1.00244 4.25614 1.00004 4.51183C0.997642 4.76752 1.10373 5.01386 1.29545 5.19779L7.24952 10.7145C7.44689 10.8973 7.71456 11 7.99365 11C8.27273 11 8.5404 10.8973 8.73777 10.7145L14.6918 5.19778C14.8892 5.01491 15 4.76691 15 4.50832C15 4.24973 14.8892 4.00173 14.6918 3.81886Z"
              fill={`${themeName === 'light' ? 'black' : 'white'}`}
            />
          </svg>
        </SelectOptionDiv2>
      ) : (
        <SelectOptionDiv theme={allStyles} className={`select ${isOpen ? 'open' : ''}`} 
        onClick={toggleDropdown}
        >
          <div >{selectedOption ? selectedOption.name : topText}</div>
          <img src={dropdown} alt="" />
        </SelectOptionDiv>
      )}
      {isOpen && (
        <Dropdown
          sessionId={sessionId}
          setSessionId={setSessionId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          dispCNS={dispCNS}
          handleDDSession={handleDDSession}
          session={session}
          options={options}
          selectedOption={selectedOption}
          onOptionClick={handleOptionClick}
          allStyles={allStyles}
        />
      )}
    </div>
  );
};

export default CustomSelect;
