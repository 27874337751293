import React, { useContext } from "react";
import { Heading, ProfileModal, FocusBox } from "./StyledComponent";
import { IoMdClose } from "react-icons/io";
import { getAuth } from "firebase/auth";
import { ThemeContext } from "../../context";
const LogoutModal = ({ allStyles, setLogoutModal }) => {
  const { themeName } = useContext(ThemeContext);
  const auth = getAuth();
  return (
    <div className="profile-modal">
      <ProfileModal
        theme={allStyles}
        className="profile-modal-container delete-modal-width"
      >
        <div
          style={{ position: "relative" }}
          onClick={() => {
            setLogoutModal(false);
          }}
        >
          <button
            className="close-button"
            aria-label="Close"
            style={{ right: "-5px", backgroundColor: themeName === 'light' ? 'rgb(180 180 180)' : 'null' }}
          >
            <IoMdClose style={{ width: "20px" }} />
          </button>
        </div>

        {/* heading */}
        <div className="logout-inside-cont">
          <Heading
            theme={allStyles}
            className="profile-edit-heading"
            style={{ textAlign: "center" }}
          >
            Do you want to Logout ?
          </Heading>

          <div className="btn-container">
            <FocusBox
              theme={allStyles}
              className="focus-button"
              type="submit"
              style={{ marginTop: "1rem" }}
              onClick={() => {
                auth.signOut();
                setLogoutModal(false);
                localStorage.removeItem("scrambleFontSize");
                localStorage.removeItem("cubeType");
                localStorage.removeItem("timerFontSize");
                localStorage.removeItem("manualTime");
                localStorage.removeItem("deleteSolve");
                localStorage.removeItem("inspection");
                localStorage.removeItem("voiceAlert");
                localStorage.removeItem("timerDisplay");
                localStorage.removeItem("emailForSignIn");
                localStorage.removeItem("current_session");
                window.location.reload();
              }}
            >
              Logout
            </FocusBox>
          </div>
        </div>
      </ProfileModal>
    </div>
  );
};

export default LogoutModal;
