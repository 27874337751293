import React, { useContext, useRef, useState } from 'react'
import { ThemeContext } from '../../../context';
import { ProfileModal, FocusBox, InputTextColor } from './styledComponent.js'
import { IoMdClose } from 'react-icons/io'
import { toast } from 'react-toastify';
import emailjs from "@emailjs/browser";
import { useIsMobile } from '../../../utils/functions/CustomHooks';
import { ReserveContext } from '../../../App.jsx';

const HelpFormModal = ({ sethelpmobileform }) => {
    const { allStyles, themeName } = useContext(ThemeContext);
    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [email, setemail] = useState("");
    const [number, setnumber] = useState("");
    const [message, setmessage] = useState("");
    const form = useRef();
    const isMobile = useIsMobile();
    // const { sethelpmobileform } = useContext(ReserveContext)

    const handleChange = (e) => {
        if (e.target.name === "fname") {
            setfname(e.target.value);
        } else if (e.target.name === "lname") {
            setlname(e.target.value);
        } else if (e.target.name === "email") {
            setemail(e.target.value);
        } else if (e.target.name === "number") {
            setnumber(e.target.value);
        } else if (e.target.name === "message") {
            setmessage(e.target.value);
        }
    };

    const handleSubmit = (e) => {
        // console.log("form: ", form.current);
        e.preventDefault();
        try {
            const sendEmail = () => {
                emailjs
                    .sendForm(
                        "upcube_service_3745lmf",
                        "template_2d746is",
                        form.current,
                        "c8CpRpK7BgEm4Cg48"
                    )
                    .then(
                        (result) => {
                            console.log(result);
                            sethelpmobileform(false);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                toast.success("Response Submitted Successfully!", { autoClose: 4000 });
            };
            sendEmail();
            localStorage.setItem("help",true);
            // window.location.reload();
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong! try again later.", {
                autoClose: 4000,
            });
        }

        setfname("");
        setlname("");
        setemail("");
        setnumber("");
        setmessage("");
    };

    return (
        <div className="profile-modal" style={{ overflow: 'hidden' }}>

            <ProfileModal theme={allStyles} className='profile-modal-container' style={{height:"80vh"}}>

                {/* close button */}
                {!isMobile ? (<div style={{ position: 'relative' }} onClick={() => { localStorage.setItem("help",true); sethelpmobileform(false) }}>
                    <button
                        className="close-button" aria-label="Close"
                        style={{ right: '-5px', backgroundColor: themeName === 'light' ? 'rgb(180 180 180)' : 'null' }}
                    >
                        <IoMdClose style={{ width: '20px' }} />
                    </button>
                </div>) : (<div style={{ position: 'relative' }} onClick={() => { localStorage.setItem("help",true);  sethelpmobileform(false)  }}>
                    <button
                        className="close-button" aria-label="Close"
                        style={{ right: '-5px', top: '-5px', backgroundColor: themeName === 'light' ? 'rgb(180 180 180)' : 'null' }}
                    >
                        <IoMdClose style={{ width: '20px' }} />
                    </button>
                </div>)}
                {/* <div className="import-modal-close-button" style={{ position: 'relative', zIndex: '1000', justifyContent: 'end', top: '1rem' }} onClick={() => {  localStorage.setItem("help",true) ; window.location.reload() }}>

                    <div
                        aria-label="Close" style={{ top: '18px' }}
                    >
                        <IoMdClose style={{  color: themeName==='light'?"black":"white" }} />
                    </div>

                </div> */}

                {/* form */}
                <div
                    theme={allStyles}
                    onSubmit={handleSubmit}
                    style={{ marginBottom: "1rem", borderRadius: "12px" }}
                >
                    <div className="form">
                        <form ref={form} style={{ padding: "1rem" }}>
                            <div className="form-content">
                                <div className="form-div">
                                    <InputTextColor theme={allStyles} htmlFor="fname">
                                        First Name
                                    </InputTextColor>

                                    <input
                                        type="text"
                                        value={fname}
                                        onChange={handleChange}
                                        name="fname"
                                        id="fname"
                                        required
                                    />
                                </div>

                                <div className="form-div">
                                    <InputTextColor theme={allStyles} htmlFor="lname">
                                        Last Name
                                    </InputTextColor>

                                    <input
                                        type="text"
                                        value={lname}
                                        onChange={handleChange}
                                        name="lname"
                                        id="lname"
                                        required
                                    />
                                </div>

                                <div className="form-div">
                                    <InputTextColor theme={allStyles} htmlFor="email">
                                        Email
                                    </InputTextColor>

                                    <input
                                        type="email"
                                        value={email}
                                        onChange={handleChange}
                                        name="email"
                                        id="email"
                                        required
                                    />
                                </div>

                                <div className="form-div">
                                    <InputTextColor theme={allStyles} htmlFor="number">
                                        Phone Number
                                    </InputTextColor>

                                    <input
                                        type="number"
                                        value={number}
                                        onChange={handleChange}
                                        name="number"
                                        id="number"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="form-div" style={{ marginTop: "1rem" }}>
                                <InputTextColor theme={allStyles} htmlFor="msg">
                                    Message
                                </InputTextColor>

                                <textarea
                                    type="text"
                                    value={message}
                                    onChange={handleChange}
                                    name="message"
                                    id="message"
                                    required
                                />
                            </div>

                            <div className="btn-container">
                                <FocusBox
                                    theme={allStyles}
                                    className="focus-button"
                                    type="submit"
                                    value="Send"
                                    onClick={ handleSubmit}
                                >
                                    Submit
                                </FocusBox>
                            </div>
                        </form>
                    </div>
                </div>

            </ProfileModal>

        </div>
    )
}

export default HelpFormModal