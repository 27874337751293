import React, { useContext, useEffect, useState } from "react";
import {
  AuthButton,
  AuthGoogleButton,
  AuthMain,
  AuthTextDiv,
  AuthTextDivDim,
} from "./styledComponents";
import { ThemeContext } from "../../../context";
import "./auth.scss";
import Logo from "../../../assets/images/logo.png";
import Google from "../../../assets/images/google.png";
import AuthImage from "../../../assets/images/upcube_banner.png";
import { getAdditionalUserInfo, getAuth } from "firebase/auth";
import { handleRedirect, sendLink } from "../../../utils/firebase/emailSignIn";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { getUser } from "../../../apis/users";
import { toast } from "react-toastify";
import axios from "axios";

const AuthComponent = ({ GoogleAuthProvider, signInWithPopup, signInWithRedirect, setguide }) => {
  const { theme: { default: allStyles } } = useContext(ThemeContext);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false)
  const [emailSend, setEmailSend] = useState(false)
  const [message, setMessage] = useState('')
  const [showEmailLogIn, setshowEmailLogIn] = useState(false)
  const [btn, setBtn] = useState(true)
  const [color, setColor] = useState('')
  const [disablebtn, SetdisableBtn] = useState(false);
  let auth = getAuth();

  useEffect(() => {
    handleRedirect();
  }, [auth]);

  const provider = new GoogleAuthProvider();

  const handleProceed = () => {
    if (!disablebtn) {
      sendLink(auth, email, setMessage, setEmailSend, setError, setBtn, setColor);
      SetdisableBtn(true);
    }
  };


  const handleGoogleAuth = async () => {
    try {
      let data = undefined;

      const result = await signInWithPopup(auth, provider);
      const credential = await GoogleAuthProvider.credentialFromResult(result);
       const {isNewUser} = getAdditionalUserInfo(result);
       
        if(isNewUser){
          localStorage.setItem('isNewUser', 1);
        }
      localStorage.setItem('Uid', result.user.uid)
      localStorage.setItem('email', result.user.email)
      localStorage.setItem('name', result.user.displayName)
      localStorage.setItem('verify_modal', 1)

      // Assuming profileData is an async function, await its completion
      // await profileData(data);
    } catch (error) {
      console.log(error);
      // toast.error("something went wrong")
    }
  };


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleProceed();
    }
  };

  return (
    <AuthMain theme={allStyles} className="auth-main">

      {/* Image */}
      <div className="auth-image">
        <img src={AuthImage} alt="" style={{ width: '100%', height: '100vh' }} />
      </div>

      {/* form */}
      <div className="auth-center">

        <div className="auth-logo">
          <img src={Logo} alt="UpCube" />
        </div>

        <div className="auth-bottom-stack">

          <AuthTextDiv theme={allStyles} className="auth-heading">
            Log In / Sign Up
          </AuthTextDiv>

          {!showEmailLogIn && <div className="auth-google-stack">

            <AuthGoogleButton
              theme={allStyles}
              className="auth-google"
              onClick={handleGoogleAuth}
            >
              <img src={Google} alt="Google" />
              <AuthTextDiv className="auth-google-text" theme={allStyles}>
                Continue with Google
              </AuthTextDiv>
            </AuthGoogleButton>

            <AuthGoogleButton
              theme={allStyles}
              className="auth-google"
              onClick={() => setshowEmailLogIn(true)}

            >
              <MdOutlineMailOutline style={{ color: 'white', fontSize: '1.3rem' }} />
              <AuthTextDiv className="auth-google-text" theme={allStyles}>
                Continue with Email
              </AuthTextDiv>
            </AuthGoogleButton>

          </div>}

          {showEmailLogIn && <div className="auth-input-button-stack">
            <div className="auth-input-stack">
              {/* <AuthTextDivDim theme={allStyles} className="auth-input-label">
                or try entering your email manually
              </AuthTextDivDim> */}
              <label className="auth-label">Email</label>

              <div className="input-error-div">
                <input
                  type="text"
                  className="auth-input"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  onKeyPress={handleKeyPress}
                />

                {
                  (error) && <div style={{ color: `${color}` }}>
                    {message}
                  </div>
                }
                {
                  emailSend && <div className="Success-mess" style={{ color: `${color}` }}>
                    {message}
                  </div>
                }

              </div>
            </div>

            {btn ? <AuthButton theme={allStyles} className="auth-button" onClick={handleProceed} style={{ marginTop: `${error || emailSend ? '0.5rem' : ''}` }}>
              Proceed
            </AuthButton> : null}
          </div>}


          <AuthTextDiv className="auth-bottom-text" theme={allStyles}>
            Developed by Cube<span style={{color: 'orange'}}>lelo</span>
          </AuthTextDiv>

        </div>

        {/* Back */}
        {showEmailLogIn && <div className="auth-back" onClick={() => setshowEmailLogIn(false)}>

          <IoIosArrowBack style={{ fontSize: '1.3rem', color: 'white' }} />
          <div className="auth-back-text">Back</div>

        </div>

        }

      </div>

    </AuthMain>
  );
};

export default AuthComponent;
