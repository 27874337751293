import React, { useState, useEffect, useContext, useRef } from 'react'
import './Solves.scss'
import { Background, FocusBoxBtn, Heading, TextColor, Button, ParaTextColor, FocusBox, SelectDiv, ButtonInactive } from './StyledComponent'
import { ReserveContext } from '../../App'
import { extractCentiseconds, extractFormatHours, extractMinutes, extractSeconds, formatter } from '../Timer/Timer Section/TimerLogic';
import NoSolves from "../../assets/images/timer/nosolves.png";
import Papa from 'papaparse';
import { FiDownload } from 'react-icons/fi'
import { toast } from 'react-toastify';
// import SolveModal from '../Session/SolveModal';
import { deleteSolve, updateSolve } from '../../apis/solves';
import { ThemeContext } from '../../context';
import DeleteModal from '../Session/DeleteModal';

const SolvesComponent = ({ allStyles,
    handleCubeType,
    setLoad,
    cubeType,
    disableCubeType,
    setSolves,
    solves
}) => {

    const [disable, setDisable] = useState(false);

    const returnTextCubeType = (input) => {
        switch (input) {
            case "222":
                return "2x2";
            case "333":
                return "3x3";
            case "333OH":
                return "3x3OH";
            case "333BF":
                return "3x3BF";
            case "444":
                return "4x4";
            case "444BF":
                return "4x4BF";
            case "555":
                return "5x5";
            case "555":
                return "5x5BF";
            case "666":
                return "6x6";
            case "777":
                return "7x7";
            case "mminx":
                return "minx";
            default:
                return input;
        }
    };

    const dropdownRef = useRef(null)

    const updateReserve = useContext(ReserveContext).updateReserve;

    var solvesData = useContext(ReserveContext).reserve.solves;
    const { 
        matrixDropdown, setmatrixDropdown,
        setSolveModal,
        setCurrentSolve,
        setsolveModalVlue,
        reserve: {sessions}
    } = useContext(ReserveContext)
    // const [solves, setSolves] = useState(solvesData);
    const { themeName } = useContext(ThemeContext)
    useEffect(() => {
        setSolves([...solvesData].reverse())
    }, [solvesData])

    let currentSession = localStorage.getItem('current_session');
    currentSession = JSON.parse(currentSession)
    let currentSessionId = currentSession.sessionId;
    let filterSolvesOnCubeType = solves ? solves?.filter((item) =>  item.cubeType === cubeType) : null

    const itemsPerPage = 9;
    const [currentPage, setCurrentPage] = useState(1);

    const filteredSolves = filterSolvesOnCubeType?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const allSolves = solves ? solves?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) : null;

    const totalAllsolvesPages = Math.ceil(solves?.length / itemsPerPage);

    const totalPages = Math.ceil(filterSolvesOnCubeType?.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const downloadCSV = () => {
        if ((filterSolvesOnCubeType?.length === 0 || solvesData == null) && allSolves?.length === 0) {
            toast.error("No Data to Export.",{autoClose:1000,className:'copy-toast'})
            return;
        }
        let data;

        if (filterSolvesOnCubeType?.length > 0) {
            data = filterSolvesOnCubeType.map(obj => {
                // if (obj.dnfAuto || obj.dnfManual) {
                //     obj.dnf = true
                // }
                // else {
                //     obj.dnf = false
                // }
                // if (obj.plus2Auto || obj.plus2Manual) {
                //     obj.plus2 = true
                // }
                // else {
                //     obj.plus2 = false
                // }
                const newObj = {
                    "Date and Time": obj.dateTime,
                    "Solve Time (Seconds)": obj.time/1000,
                    "Cube Type": obj.cubeType,
                    "Scramble": obj.scramble,
                    sessionName: sessions.find(session => session.sessionId === obj.sessionId)?.name, 
                    "DNF": obj.dnfAuto || obj.dnfManual ? true : false,
                    "plus 2": obj.plus2Auto || obj.plus2Manual? true : false,

                }
                return newObj;
            });
            const csvData = Papa.unparse(data);
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'solves.csv';
            a.click();
            URL.revokeObjectURL(url);
        } else if (allSolves?.length > 0) {
            let count = 1;
            let data = solvesData.map((obj, index) => {
                let prevSession;
                if (index === 0) {
                    prevSession = solvesData[0].sessionId;
                } else {
                    prevSession = solvesData[index - 1].sessionId;
                }
                let curSession = solvesData[index].sessionId;
                if (curSession !== prevSession) {
                    count++;
                }
                // Creating a new object with the added property
                const newObj = {
                    "Date and Time": obj.dateTime,
                    "Solve Time (Seconds)": obj.time / 1000,
                    "Cube Type": obj.cubeType,
                    "Scramble": obj.scramble,
                    sessionName: sessions.find(session => session.sessionId === obj.sessionId)?.name, 
                    "DNF": obj.dnfAuto || obj.dnfManual,
                    "plus 2": obj.plus2Auto || obj.plus2Manual
                };
                return newObj;
            });
        
            const csvData = Papa.unparse(data);
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'solves.csv';
            a.click();
            URL.revokeObjectURL(url);
        }
    }

    useEffect(() => {
        setDisable(disableCubeType);
    }, [disableCubeType]);

    useEffect(() => {
        setCurrentPage(1)
    }, [cubeType])

    const returnMatrixCube = (input) => {
        switch (input) {
            case "all":
                return "All";
            case "222":
                return "2x2";
            case "333":
                return "3x3";
            case "333OH":
                return "3x3 OH";
            case "333BF":
                return "3x3 BLD";
            case "444":
                return "4x4";
            case "444BF":
                return "4x4 BLD";
            case "555":
                return "5x5";
            case "555BF":
                return "5x5 BLD";
            case "666":
                return "6x6";
            case "777":
                return "7x7";
            case "skewb":
                return "Skewb";
            case "pyra":
                return "Pyraminx";
            case "minx":
                return "Megaminx";
            case "clock":
                return "Clock";
            case "sq1":
                return "Square-1";
            default:
                return;
        }
    };

    const cubeTypeOptions = [
        { value: '222', label: '2x2' },
        { value: '333', label: '3x3' },
        { value: '444', label: '4x4' },
        { value: '555', label: '5x5' },
        { value: '666', label: '6x6' },
        { value: '777', label: '7x7' },
        { value: '333OH', label: '3x3 OH' },
        { value: '333BF', label: '3x3 BLD' },
        { value: 'skewb', label: 'Skewb' },
        { value: 'pyra', label: 'Pyraminx' },
        { value: 'minx', label: 'Megaminx' },
        { value: 'clock', label: 'Clock' },
        { value: 'sq1', label: 'Square-1' },
        { value: '444BF', label: '4x4 BLD' },
        { value: '555BF', label: '5x5 BLD' },
      ]

    const handleCloseSidebar = () => {
        setmatrixDropdown(false)
    }

    useEffect(() => {
        // Handle clicks outside the sidebar
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target) && e.target.id !== 'select-cubetype') {
                handleCloseSidebar()
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleOutsideClick);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [matrixDropdown]);


    return (
        <div className='solves-container'>

            <div className='solves-header'>

                {/* stats heading */}

                <Heading theme={allStyles} className='solves-heading'>
                    Solves
                </Heading>

                {/* stats buttons */}

                <div className='solves-btn'>

                    <ButtonInactive theme={allStyles} className="cube-type-select" id="select-cubetype" onClick={() => { setmatrixDropdown(!matrixDropdown) }}>

                        {returnMatrixCube(cubeType)}

                        {/* <img src={dropdown} alt="" /> */}
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6918 3.81886C14.4945 3.63604 14.2268 3.53333 13.9477 3.53333C13.6686 3.53333 13.401 3.63604 13.2036 3.81886L7.99365 8.64608L2.78371 3.81886C2.5852 3.64122 2.31933 3.54292 2.04337 3.54515C1.7674 3.54737 1.50342 3.64993 1.30827 3.83074C1.11313 4.01155 1.00244 4.25614 1.00004 4.51183C0.997642 4.76752 1.10373 5.01386 1.29545 5.19779L7.24952 10.7145C7.44689 10.8973 7.71456 11 7.99365 11C8.27273 11 8.5404 10.8973 8.73777 10.7145L14.6918 5.19778C14.8892 5.01491 15 4.76691 15 4.50832C15 4.24973 14.8892 4.00173 14.6918 3.81886Z" 
                             fill={themeName==="light"?"black":"white"} />
                        </svg>


                        {matrixDropdown && <ButtonInactive theme={allStyles} className="matrix-cubetype" ref={dropdownRef} 
                         id={`${themeName ==='light' ?'style-1' :""}`}>

                            {cubeTypeOptions.map((cube) => {
                                return (
                                    <SelectDiv theme={allStyles} className={`dropdown-matrix-cubetype ${cubeType === cube.value ? 'selected' : ''}`} key={cube.value}
                                        onClick={() => {
                                            handleCubeType(cube.value);
                                            setmatrixDropdown(!matrixDropdown)
                                            setLoad(false);
                                        }}
                                    >
                                        {cube.label}

                                        {cubeType === cube.value && <svg xmlns="http://www.w3.org/2000/svg" viewBox="-49 141 512 512" width="16" height="16" aria-hidden="true" className="dropdown-tick" fill={themeName==='light'?"#000000":"#FFFFFF"}><path d="M127 552.25a24.92 24.92 0 0 1-17.678-7.322l-101-101c-9.763-9.763-9.763-25.593 0-35.355 9.764-9.764 25.592-9.764 35.355 0l83.339 83.34 243.323-242.857c9.771-9.754 25.602-9.74 35.355.033 9.754 9.772 9.739 25.602-.033 35.355l-261 260.5A24.92 24.92 0 0 1 127 552.25"></path></svg>}
                                    </SelectDiv>
                                )
                            })}

                        </ButtonInactive>}

                    </ButtonInactive>


                    <FocusBox theme={allStyles}
                        onClick={downloadCSV}
                        className='solves-export-button '
                    >
                        Export
                        <FiDownload />
                    </FocusBox>

                </div>

            </div>


            <div className='solves-content'>

                <Heading theme={allStyles}>
                    Total Solves : {cubeType === 'all' ? (
                        <>
                            {allSolves?.length === 0 ? 'NA' : allSolves?.length}
                        </>
                    )
                        : (
                            <>
                                {filterSolvesOnCubeType?.length === 0 ? 'NA' : filterSolvesOnCubeType?.length}
                            </>
                        )
                    }

                </Heading>

                {(filterSolvesOnCubeType?.length === 0 || solvesData == null) && cubeType !== "all" ? (
                    <>
                        <Background theme={allStyles} className='no-solves-conatiner'>
                            <div className='no-solves-div'>
                                <img src={NoSolves} alt="NoSolves" />
                                <ParaTextColor theme={allStyles}>No solves yet</ParaTextColor>
                                <ParaTextColor theme={allStyles}>Watch this place for all your solves</ParaTextColor>
                            </div>
                        </Background>
                        {(!!filterSolvesOnCubeType?.length) && (<div className="solves-pagination-controls">
                            <FocusBoxBtn theme={allStyles}
                                disabled
                                className='focus-button solve-modal-scramble-button pagination-button'
                            >
                                &larr; Prev
                            </FocusBoxBtn>

                            <ParaTextColor theme={allStyles}>Page 0 of 0</ParaTextColor>
                            <FocusBoxBtn theme={allStyles}
                                disabled
                                className='focus-button solve-modal-scramble-button pagination-button'
                            >
                                Next &rarr;
                            </FocusBoxBtn>
                        </div>)}
                    </>
                ) :

                    <div className='all-solves-container'>

                        <div className='solves-content-container'>

                            {filteredSolves?.map((solves, key) => {
                                const dateString = solves.dateTime
                                const date = new Date(dateString);
                                return (

                                    <Background theme={allStyles} className='solves-content-box' key={key} onClick={() => {
                                        setSolveModal(true)
                                        setCurrentSolve(solves)
                                        setsolveModalVlue({
                                            scramble: solves.scramble,
                                            date: solves.dateTime,
                                            cubetype: solves.cubeType,
                                            plus2Auto: solves.plus2Auto,
                                            plus2Manual: solves.plus2Manual,
                                            dnfAuto: solves.dnfAuto,
                                            dnfManual: solves.dnfManual,
                                            solveTime: solves.time
                                        })
                                    }}>

                                        <div className='solves-content-box-left'>

                                            <TextColor theme={allStyles} className='solves-content-box-label'>
                                                {solves.dnfAuto || solves.dnfManual ? null : extractFormatHours(solves.time)}
                                                {solves.dnfAuto || solves.dnfManual ? null : extractMinutes(solves.time).split(":")[0] > 0 ? extractMinutes(solves.time).split(":")[0] % 60 + ':' : null}
                                                {solves.dnfAuto || solves.dnfManual ? "DNF" : (solves.plus2Auto || solves.plus2Manual ? `${extractSeconds(solves.time + 2000)}` : extractSeconds(solves.time))}
                                                {/* {solves.dnfAuto || solves.dnfManual ? null : extractCentiseconds(solves.time)} */}
                                                <span style={{ fontSize: '15px' }} >{solves.dnfAuto || solves.dnfManual ? null : extractCentiseconds(solves.time)}</span>
                                                
                                            </TextColor>
                                            <TextColor theme={allStyles} className='solves-content-box-solve-id'>
                                                {date.toLocaleString('en-US', { month: 'short' })} {date.getDate()}, {date.getFullYear()}
                                            </TextColor>

                                        </div>

                                        <div className='solves-content-box-right'>
                                            <div className='solves-content-box-buttons'>
                                                <Button theme={allStyles} className='solves-content-box-buttons-matrix'>{returnTextCubeType(solves.cubeType)}</Button>
                                            </div>
                                        </div>

                                    </Background>

                                )
                            })}

                            {
                                cubeType === 'all' &&

                                <>
                                    {
                                        allSolves.length === 0 ?
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                <Background theme={allStyles} className='no-solves-conatiner'>
                                                    <div className='no-solves-div'>
                                                        <img src={NoSolves} alt="NoSolves" />
                                                        <ParaTextColor theme={allStyles}>No Solves yet</ParaTextColor>
                                                        <ParaTextColor theme={allStyles}>Watch this place for all your solves</ParaTextColor>
                                                    </div>
                                                </Background>

                                               {!!allSolves?.length && (<div className="solves-pagination-controls">
                                                    <FocusBoxBtn theme={allStyles}
                                                        disabled
                                                        className='focus-button solve-modal-scramble-button pagination-button'
                                                    >
                                                        &larr; Prev
                                                    </FocusBoxBtn>

                                                    <ParaTextColor theme={allStyles}>Page 0 of 0</ParaTextColor>
                                                    <FocusBoxBtn theme={allStyles}
                                                        disabled
                                                        className='focus-button solve-modal-scramble-button pagination-button'
                                                    >
                                                        Next &rarr;
                                                    </FocusBoxBtn>
                                                </div>)}
                                            </div>
                                            :
                                            <>
                                                {allSolves?.map((solves, key) => {
                                                    const dateString = solves.dateTime
                                                    const date = new Date(dateString);
                                                    return (

                                                        <Background theme={allStyles} className='solves-content-box' key={key} onClick={() => {
                                                            setSolveModal(true)
                                                            setCurrentSolve(solves)
                                                            setsolveModalVlue({
                                                                scramble: solves.scramble,
                                                                date: solves.dateTime,
                                                                cubetype: solves.cubeType,
                                                                plus2Auto: solves.plus2Auto,
                                                                plus2Manual: solves.plus2Manual,
                                                                dnfAuto: solves.dnfAuto,
                                                                dnfManual: solves.dnfManual,
                                                                solveTime: solves.time
                                                            })
                                                        }}>

                                                            <div className='solves-content-box-left'>

                                                                <TextColor theme={allStyles} className='solves-content-box-label'>
                                                                    {solves.dnfAuto || solves.dnfManual ? null : extractFormatHours(solves.time)}
                                                                    {solves.dnfAuto || solves.dnfManual ? null : extractMinutes(solves.time).split(":")[0] > 0 ? extractMinutes(solves.time).split(":")[0] % 60 + ':' : null}
                                                                    {solves.dnfAuto || solves.dnfManual ? "DNF" : (solves.plus2Auto || solves.plus2Manual ? `${extractSeconds(solves.time + 2000)}` : extractSeconds(solves.time))}
                                                                    <span style={{ fontSize: '12px' }}>{solves.dnfAuto || solves.dnfManual ? null : extractCentiseconds(solves.time)}</span>
                                                                </TextColor>
                                                                <TextColor theme={allStyles} className='solves-content-box-solve-id'>
                                                                    {date.toLocaleString('en-US', { month: 'short' })} {date.getDate()}, {date.getFullYear()}
                                                                </TextColor>

                                                            </div>

                                                            <div className='solves-content-box-right'>
                                                                <div className='solves-content-box-buttons'>
                                                                    <Button theme={allStyles} className='solves-content-box-buttons-matrix'>{returnTextCubeType(solves.cubeType)}</Button>

                                                                    {cubeType === '333BF' ? <Button theme={allStyles} className='solves-content-box-buttons-fold'>BlindFold</Button> : ''}
                                                                </div>
                                                            </div>

                                                        </Background>

                                                    )
                                                })}
                                            </>
                                    }
                                </>

                            }

                        </div>

                        {((cubeType !== 'all' && totalPages > 1) || (cubeType === 'all' && totalAllsolvesPages > 1)) && (<div className="solves-pagination-controls">
                            <FocusBoxBtn theme={allStyles}
                            className='focus-button solve-modal-scramble-button pagination-button'
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                style={{ display: `${currentPage === 1 ? 'none' : 'block'}` }}
                            >
                                &larr; Prev
                            </FocusBoxBtn>

                            <ParaTextColor theme={allStyles}>Page {currentPage} of {totalPages || totalAllsolvesPages}</ParaTextColor>

                            <FocusBoxBtn theme={allStyles}
                                onClick={() => handlePageChange(currentPage + 1)}
                                className='focus-button solve-modal-scramble-button pagination-button'
                                disabled={currentPage === totalPages || currentPage === totalAllsolvesPages}
                                style={{ display: `${currentPage === totalPages || currentPage === totalAllsolvesPages ? 'none' : 'block'}` }}

                            >
                                Next &rarr;
                            </FocusBoxBtn>
                        </div>)}

                    </div>
                }

            </div>
        </div>
    )
}

export default SolvesComponent