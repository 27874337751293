import { getAllSolves } from "../../apis/solves";
import { getAllSessions } from "../../apis/sessions";
import { getUser } from "../../apis/users";
import "driver.js/dist/driver.css";
import { toast } from "react-toastify";
import axios from "axios";

const profileData = async(data)=>{
    
  const formData = {
    Uid: data.uid,
    Email: data.email,
    Username: `testuser_${Math.random().toString(36).substring(2, 8)}`,
    Gender: "other",
    Name: data.name,
    Dob: "1989-12-31T18:30:00.000Z",
    Country: "country",
    State: "state",
    City: 'city',
    Mobile: "1234567890",
    Bio: '',
    ProfilePic: '',
    CoverPic: '',
    JoinDate: '',
    Social: {
      Youtube: '',
      Instagram: '',
      Twitter: '',
      Reddit: '',
      WCA:  '',
      Facebook: '',
    },
    CubeSpecifies: {
      Method333: '',
      Goal333: '',
      MainCube: '',
      FavouriteEvent: '',
    }
  };
  try {
    const response = await axios.post('https://upcube.el.r.appspot.com/api/v1/auth/signup', formData, {
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if required
      }
    });
    if (!response) {
      toast.error('Failed to create user .');
    } else {
      window.location.href = '/timer'
      toast.success('Account created successfully.');
    }

  } catch (error) {
    toast.error('Failed to create user .');
    console.error('Error submitting form:', error);
    // Handle error (e.g., show error message)
  }
}

export const fetchAndSave = async () => {
  // const driverObj = driver({
  //   showProgress: true,
  //   steps: [
  //     {
  //       popover: {
  //         title: "Welcome to UpCube!",
  //         description: "Lets Start the tour.",
  //         onNextClick: () => {
  //           driverObj.moveNext();
  //         },
  //       },
  //     },
  //     {
  //       element: "#create-session",
  //       popover: {
  //         title: "Create a Session",
  //         description: "Enter your Session Name and Continue.",
  //         onNextClick: async () => {
  //           let sessions = (await getAllSessions()).sessions;
  //           if ((sessions && sessions.length == 0) !== null) {
  //             driverObj.moveNext();
  //           } else {
  //             toast.error("Create a Session to continue..");
  //           }
  //         },
  //       },
  //       // onDeselected is called when the element is deselected.
  //       // Here we are simply removing the element from the DOM.
  //       onDeselected: () => {
  //         // .. remove element
  //         // document.querySelector("#create-session")?.remove();
  //       },
  //     },
  //     {
  //       element: "#select-cubetype",
  //       popover: {
  //         title: "Select a cubetype",
  //         description: "You can select a cubetype from here.",
  //       },
  //     },

  //     {
  //       element: "#start-inspection",
  //       popover: {
  //         title: "Press the 'Space' button to start the inspection",
  //         description:
  //           "By pressing the 'Space' button you can start the inspection.",
  //       },
  //     },

  //     {
  //       element: "#start-solving",
  //       popover: {
  //         title: "Long press the 'Space' button to start the solve",
  //         description:
  //           "By pressing the 'Space' button for seconds, you can start solving cube.",
  //       },
  //     },

  //     {
  //       popover: {
  //         title: "Happy Solving",
  //         description:
  //           "And that is all, go ahead and start solving cube in our application.",
  //       },
  //     },
  //   ],
  // });

  let solves = (await getAllSolves()).solves || [];
  let sessions = (await getAllSessions()).sessions || [];
  // console.log(sessions && sessions.length == 0)
  if ((sessions && sessions.length == 0) == null) {
    // driverObj.drive();
  }

  let user = await getUser();

  if(!user)
    {
      const uid = localStorage.getItem('Uid');
      const email = localStorage.getItem('email');
      const name = localStorage.getItem('name');
      const data={uid:uid,email:email,name:name};
      await profileData(data);
    }
    localStorage.removeItem('Uid');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
  return {
    
    solves: solves,
    sessions: sessions,
    user: user,
  };
};
