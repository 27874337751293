import React, { useContext } from 'react' 
import { deleteSolve, updateSolve } from '../../../apis/solves';
import { ReserveContext } from '../../../App';
import { toast } from 'react-toastify';
import SolveModal from './SolveModal';

const MainSolveModal = ({allStyles, setSolves}) => {

    const updateReserve = useContext(ReserveContext).updateReserve;

    const {
        setDeleteSolveModal,
        DeleteSolve,
        solveModal, setSolveModal,
        solveModalVlue, setsolveModalVlue,
        CurrentSolve,
        setSolveId
    } = useContext(ReserveContext)

    const handleDeleteSolve = async (solveId) => {
        try {
            await deleteSolve(solveId);
            // setSolves((prevSolves) => prevSolves.filter((solve) => solve.solveId !== solveId));
            updateReserve((prevReserve) => ({
                ...prevReserve,
                solves: prevReserve.solves.filter((solve) => solve.solveId !== solveId),
            }));
            toast.success("Solve deleted successfully.",{autoClose:1000,className:'copy-toast'})
        } catch (error) {
            toast.error("Error deleting solve.",{autoClose:1000,className:'copy-toast'})
            console.error("Error deleting solve:", error);
        }
    };

    const handleUpdateSolveForPlus2 = async (solveToUpdate) => {
        try {
            const updatedSolve = { ...solveToUpdate, plus2Manual: !solveToUpdate.plus2Manual };
            setsolveModalVlue({ ...solveModalVlue, plus2Manual: !solveModalVlue.plus2Manual })
            await updateSolve(updatedSolve);
            setSolves((prevSolves) =>
                prevSolves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                )
            );
            updateReserve((prevReserve) => ({
                ...prevReserve,
                solves: prevReserve.solves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                ),
            }));
        } catch (error) {
            console.error("Error updating solve:", error);
        }
    };

    const handleUpdateSolveDNF = async (solveToUpdate) => {
        try {
            const updatedSolve = { ...solveToUpdate, dnfManual: !solveToUpdate.dnfManual };
            setsolveModalVlue({ ...solveModalVlue, dnfManual: !solveModalVlue.dnfManual })

            await updateSolve(updatedSolve);
            setSolves((prevSolves) =>
                prevSolves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                )
            );
            updateReserve((prevReserve) => ({
                ...prevReserve,
                solves: prevReserve.solves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                ),
            }));

        }
        catch (error) {
            console.error("Error updating solve:", error);
        }
    }
    return (
        <>
            {solveModal && <div style={{width: "100%", height: "100%"}} onClick={() => { console.log("parent>>>>"); setSolveModal(false)}} >
             <SolveModal
                setSolveModal={setSolveModal}
                allStyles={allStyles}
                solveModalVlue={solveModalVlue}
                handleUpdateSolveForPlus2={handleUpdateSolveForPlus2}
                handleUpdateSolveDNF={handleUpdateSolveDNF}
                CurrentSolve={CurrentSolve}
                setDeleteSolveModal={setDeleteSolveModal}
                setSolveId={setSolveId}
                handleDeleteSolve={handleDeleteSolve}
                DeleteSolve={DeleteSolve}
                />
            </div>
                }
        </>
    )
}

export default MainSolveModal