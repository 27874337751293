// utils/firebase/emailSignIn.js

import {
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  getAuth,
  getAdditionalUserInfo,
} from "firebase/auth";

export const sendLink = (auth,enteredEmail,setMessage,setEmailSend,setError,setBtn, setColor) => {
  const actionCodeSettings = {
    url: process.env.REACT_APP_CONFIRMATION_URL || "https://upcube.io/authenticate",
    handleCodeInApp: true,
  };

  sendSignInLinkToEmail(auth, enteredEmail, actionCodeSettings)
    .then(() => {
      window.localStorage.setItem("emailForSignIn", enteredEmail);
      setEmailSend(true)
      setColor('green')
      setMessage("Email sent. Check your inbox.");
      setBtn(false)
    })
    .catch((error) => {
      setError(true)
      setColor('red')
      setMessage("Error sending email.")
      console.error("Error sending email:", error.message);
    });
};

export const handleRedirect = () => {
  let auth = getAuth()
  if (isSignInWithEmailLink(auth, window.location.href)) {
    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }
    signInWithEmailLink(auth, email, window.location.href)
      .then((response) => {
        console.log(response);
        window.localStorage.removeItem('emailForSignIn');
        localStorage.setItem('Uid',response.user.uid)
      localStorage.setItem('email',response.user.email)
      localStorage.setItem('name',response.user.displayName);
      const {isNewUser} = getAdditionalUserInfo(response);
             
      if(isNewUser){
        localStorage.setItem('isNewUser', 1);
      }
      localStorage.setItem('verify_modal', 1)
      
        // navigate("/authenticate", { replace: true });
        window.location.href = 'https://upcube.io/timer';
      })
      .catch((error) => {
        console.error("Error signing in with email link:", error.message);
      });
  }
};
