import React, { useContext, useState } from 'react'
import { ThemeContext } from "../../context";
import SolvesComponent from '../../components/Solves/SolvesComponent'
import MainSolveModal from '../../components/Modals/SolveModal/MainSolveModal';
import MainDeleteSolveModal from '../../components/Modals/DeleteSolveModal/MainDeleteSolveModal';
import { ReserveContext } from '../../App';

const Solves = () => {

  const [cubeType, setCubeType] = useState(localStorage.getItem("cubeType") || "all");
  const [disableCubeType, setDisableCubeType] = useState(false);
  const [solves, setSolves] = useState([]);
  const [load, setLoad] = useState(false);
  const handleCubeType = (type) => {
    setCubeType(type);
  };
 
  const { allStyles } =
    useContext(ThemeContext);

  const {
      // setDeleteSolveModal,
      // DeleteSolve,
      DeleteSolveModal,
      solveModal, setSolveModal,
      // solveModalVlue, setsolveModalVlue,
      // CurrentSolve,
      // setSolveId
  } = useContext(ReserveContext) 
  
  return (
    <>

   {DeleteSolveModal && <MainDeleteSolveModal allStyles={allStyles} setSolves={setSolves} />}

    {solveModal && <MainSolveModal allStyles={allStyles} setSolves={setSolves} />}

     {!solveModal && !DeleteSolveModal && <SolvesComponent 
      allStyles={allStyles}
      cubeType={cubeType}
      handleCubeType={handleCubeType}
      setLoad={setLoad}
      disableCubeType={disableCubeType}
      solves={solves}
      setSolves={setSolves} 
      />}
    
    </>
  )
}

export default Solves