import React, { useState, useEffect, useContext, useRef } from 'react'
import './Stats.scss'
import { ButtonInactive, Background, BackgroundBox, Heading, TextColor, SelectDiv } from './StyledComponent'
import { formatter, statsFormatter } from '../Timer/Timer Section/TimerLogic';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
// import { Chart } from "react-google-charts";
import { MdOutlineTimer, MdOutlineCalendarMonth } from 'react-icons/md'
import { IoIosCube } from 'react-icons/io'
import { GoHash } from 'react-icons/go'
import { LuArrowUpToLine } from 'react-icons/lu'
import { RiUserHeartLine, RiAwardFill } from 'react-icons/ri'
import NoSolves from "../../assets/images/timer/nosolves.png";
import { ReserveContext } from '../../App';
import { ThemeContext } from '../../context';
import { CalculateAvg } from '../../utils/functions/calculations';
import { ParaTextColor } from '../Solves/StyledComponent';

const StatsComponent = ({ allStyles,
    handleCubeType,
    setLoad,
    cubeType,
    disableCubeType,
    stats
}) => {

    const [disable, setDisable] = useState(false);
    const [All, setAll] = useState(true)
    const [matrix, setmatrix] = useState(false)
    let { matrixDropdown, setmatrixDropdown } = useContext(ReserveContext)
    const dropdownRef = useRef(null);
    var solvesData = useContext(ReserveContext).reserve.solves;
    const { themeName } = useContext(ThemeContext)
    console.log(solvesData)
    const filteredSolves = solvesData?.filter((item) => item.cubeType === cubeType)

    const daysSpent = new Set()
    let NumberOfEvents = 0

    function formatDateString(dateString) {
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
        };

        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString("en-IN", options);

        const time = date.toLocaleTimeString([], {
            hour: "numeric",
            minute: "2-digit",
        });

        return `${formattedDate}`;
    }

    solvesData?.map((item) => {
        daysSpent.add(formatDateString(item.dateTime))
    })

    let totalSolvesCount = 0;
    let totalTimeSpent = 0;

    for (const key in stats) {
        totalSolvesCount += stats[key].solvesCount;
        totalTimeSpent += stats[key].timeSpent

        if (stats[key].solvesCount > 0) {
            NumberOfEvents += 1
        }
    }

    useEffect(() => {
        setDisable(disableCubeType);
    }, [disableCubeType]);

    const returnTextCubeType = (input) => {
        switch (input) {
            case "222":
                return "two";
            case "333":
                return "three";
            case "333OH":
                return "threeOH";
            case "333BF":
                return "threeBF";
            case "444":
                return "four";
            case "444BF":
                return "fourBF";
            case "555":
                return "five";
            case "555BF":
                return "fiveBF";
            case "666":
                return "six";
            case "777":
                return "seven";
            case "mminx":
                return "megaminx";
            default:
                return input;
        }
    };

    const filteredSolvestype = Object.entries(stats).filter(([key, value]) => value.solvesCount > 0)
    let highestSolvesCount = -1;
    let highestTimeCount = -1;
    let highestSolvesCountObject = null;
    let highestTimeCountObject = null

    for (const key in stats) {
        if (stats[key].solvesCount > highestSolvesCount) {
            highestSolvesCount = stats[key].solvesCount;
            highestSolvesCountObject = key;
        }

        if (stats[key].timeSpent > highestTimeCount) {
            highestTimeCount = stats[key].timeSpent;
            highestTimeCountObject = key;
        }
    }

    const handleAllContent = () => {
        setAll(true)
        setmatrix(false)
    }

    const handleMatrixContent = () => {
        setAll(false)
        setmatrix(true)
    }

    // returning the matrix type

    const returnMatrixCubeType = (input) => {
        switch (input) {
            case "two":
                return "2x2";
            case "three":
                return "3x3";
            case "threeOH":
                return "3x3OH";
            case "threeBF":
                return "3x3BLD";
            case "four":
                return "4x4";
            case "fourBF":
                return "4x4BF";
            case "five":
                return "5x5";
            case "fiveBF":
                return "5x5BF";
            case "six":
                return "6x6";
            case "seven":
                return "7x7";
            case "minx":
                return "megaminx";
            default:
                return input;
        }
    };

    // function to generate random color

    function getRandomColor() {
        const colorArray = ['#F9F1F0', '#FADCD9', '#F8AFA6', '#F79489', '#B80454', '#B175FF', '#DCBCD0', '#FCA7A5', '#5A3E79', '#A76286',
            '#EC0062', '#9A7C8A', '#FF0BAC', '#DFDFE0', '#EC0062', '#9A7C8A', '#FF0BAC', '#DFDFE0']

        let randomIndex = Math.floor(Math.random() * colorArray?.length)


        return colorArray[randomIndex];
    }

    // returning the array of objects for pie chart by time
    const resultArrayByTime = filteredSolvestype.map((item, key) => {
        const percentage = ((item[1].timeSpent / totalTimeSpent) * 100).toFixed(2);
        const matrixType = returnMatrixCubeType(item[0]);

        // chartByTime.push([matrixType, percentage])
        const object = {
            id: key,
            value: percentage,
            label: matrixType,
            color: getRandomColor()
        };

        return object
    });
    console.log("filteredSolvestype", filteredSolvestype);
    console.log("resultArrayByTime", resultArrayByTime);

    //   function transformData(data) {
    //     // Initialize the result array with the header
    //     let result = [["Events", "Count"]];

    //     // Loop through the data
    //     for(let i = 0; i < data.length; i++) {
    //       // Push the label and value of each item in the data array to the result
    //       result.push([data[i].label, data[i].value]);
    //     }

    //     // Return the result
    //     return result;
    //   }

    // returning the array of objects for pie chart by Solves

    const resultArrayBySolves = filteredSolvestype.map((item, key) => {
        const percentage = Math.floor((item[1].solvesCount / totalSolvesCount) * 100);
        const matrixType = returnMatrixCubeType(item[0]);

        const object = {
            id: key,
            value: percentage,
            label: matrixType,
            color: getRandomColor()
        };

        return object;
    });

    const reversedSolves = filteredSolves?.reverse()

    const filteredStatsWithoutDNF10 = reversedSolves?.length >= 10 ? reversedSolves.slice(0, 10)?.filter(item => item.dnfManual === false) : ""
    const filteredStatsWithoutDNF25 = reversedSolves?.length >= 25 ? reversedSolves.slice(0, 25)?.filter(item => item.dnfManual === false) : ""
    const filteredStatsWithoutDNF50 = reversedSolves?.length >= 50 ? reversedSolves.slice(0, 50)?.filter(item => item.dnfManual === false) : ""
    const filteredStatsWithoutDNF100 = reversedSolves?.length >= 100 ? reversedSolves.slice(0, 100)?.filter(item => item.dnfManual === false) : ""
    const filteredStatsWithoutDNF500 = reversedSolves?.length >= 500 ? reversedSolves.slice(0, 500)?.filter(item => item.dnfManual === false) : ""
    const filteredStatsWithoutDNF1000 = reversedSolves?.length >= 1000 ? reversedSolves.slice(0, 1000)?.filter(item => item.dnfManual === false) : ""


    const returnMatrixCube = (input) => {
        switch (input) {
            case "222":
                return "2x2";
            case "333":
                return "3x3";
            case "333OH":
                return "3x3 OH";
            case "333BF":
                return "3x3 BLD";
            case "444":
                return "4x4";
            case "444BF":
                return "4x4 BLD";
            case "555":
                return "5x5";
            case "555BF":
                return "5x5 BLD";
            case "666":
                return "6x6";
            case "777":
                return "7x7";
            case "skewb":
                return "Skewb";
            case "pyra":
                return "Pyraminx";
            case "minx":
                return "Megaminx";
            case "clock":
                return "Clock";
            case "sq1":
                return "Square-1";
            default:
                return;
        }
    };

    const cubeTypeOptions = [
        { value: '222', label: '2x2' },
        { value: '333', label: '3x3' },
        { value: '444', label: '4x4' },
        { value: '555', label: '5x5' },
        { value: '666', label: '6x6' },
        { value: '777', label: '7x7' },
        { value: '333OH', label: '3x3 OH' },
        { value: '333BF', label: '3x3 BLD' },
        { value: 'skewb', label: 'Skewb' },
        { value: 'pyra', label: 'Pyraminx' },
        { value: 'minx', label: 'Megaminx' },
        { value: 'clock', label: 'Clock' },
        { value: 'sq1', label: 'Square-1' },
        { value: '444BF', label: '4x4 BLD' },
        { value: '555BF', label: '5x5 BLD' },
    ]

    const handleCloseSidebar = () => {
        setmatrixDropdown(false)
    }

    useEffect(() => {
        // Handle clicks outside the sidebar
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target) && e.target.id !== 'select-cubetype') {
                handleCloseSidebar()
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleOutsideClick);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [matrixDropdown]);



    return (

        <div className='stats-container'>

            <div className='stats-header'>

                {/* stats heading */}

                <Heading theme={allStyles} className='stats-heading'>
                    Statistics
                </Heading>

                {/* stats buttons */}

                <div className='stats-btn'>

                    <ButtonInactive theme={allStyles} onClick={handleAllContent} className='stats-btn-button' style={{ border: 'none', borderBottom: `${All ? themeName === 'light' ? '5px solid black' : '3px solid white' : 'none'}` }} >
                        All Stats
                    </ButtonInactive>

                    <ButtonInactive theme={allStyles} className="cube-type-select" id="select-cubetype" onClick={() => { setmatrixDropdown(!matrixDropdown); handleMatrixContent() }} style={{ borderBottom: `${matrix ? themeName === 'light' ? '5px solid black' : '3px solid white' : 'none'}` }}>

                        {returnMatrixCube(cubeType)}

                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6918 3.81886C14.4945 3.63604 14.2268 3.53333 13.9477 3.53333C13.6686 3.53333 13.401 3.63604 13.2036 3.81886L7.99365 8.64608L2.78371 3.81886C2.5852 3.64122 2.31933 3.54292 2.04337 3.54515C1.7674 3.54737 1.50342 3.64993 1.30827 3.83074C1.11313 4.01155 1.00244 4.25614 1.00004 4.51183C0.997642 4.76752 1.10373 5.01386 1.29545 5.19779L7.24952 10.7145C7.44689 10.8973 7.71456 11 7.99365 11C8.27273 11 8.5404 10.8973 8.73777 10.7145L14.6918 5.19778C14.8892 5.01491 15 4.76691 15 4.50832C15 4.24973 14.8892 4.00173 14.6918 3.81886Z"
                                fill={themeName === "light" ? "black" : "white"} />
                        </svg>

                        {matrixDropdown && <ButtonInactive theme={allStyles} className="matrix-cubetype" ref={dropdownRef}
                            id={`${themeName === 'light' ? 'style-1' : ""}`} >

                            {cubeTypeOptions.map((cube) => {
                                return (
                                    <SelectDiv theme={allStyles} className={`dropdown-matrix-cubetype ${cubeType === cube.value ? 'selected' : ''}`} key={cube.value}

                                        onClick={() => {
                                            handleMatrixContent()
                                            setAll(false)
                                            setmatrix(true)
                                            handleCubeType(cube.value);
                                            setmatrixDropdown(!matrixDropdown)
                                            setLoad(false);
                                        }}
                                    >
                                         <span style={{ color: cubeType === cube.value && themeName === 'light' ? 'purple' : ''}} >{cube.label}</span>

                                        {cubeType === cube.value && <svg xmlns="http://www.w3.org/2000/svg" viewBox="-49 141 512 512" width="16" height="16" aria-hidden="true" className="dropdown-tick" fill={themeName === 'light' ? "#00000" : "#FFFFFF"}><path d="M127 552.25a24.92 24.92 0 0 1-17.678-7.322l-101-101c-9.763-9.763-9.763-25.593 0-35.355 9.764-9.764 25.592-9.764 35.355 0l83.339 83.34 243.323-242.857c9.771-9.754 25.602-9.74 35.355.033 9.754 9.772 9.739 25.602-.033 35.355l-261 260.5A24.92 24.92 0 0 1 127 552.25"></path></svg>}
                                    </SelectDiv>
                                )
                            })}

                        </ButtonInactive>}

                    </ButtonInactive>

                </div>

            </div>

            <div className="stats-content">

                {All &&
                    <>

                        {/* All Stats Overview */}

                        <div className='stats-overview'>

                            <Heading theme={allStyles}>Overview</Heading>

                            <Background theme={allStyles} className='stats-overview-content'>

                                {/* time spent */}

                                <BackgroundBox theme={allStyles} className='stats-box'>

                                    <div className='stats-box-content'>

                                        <div className='stats-box-content-heading'>

                                            <div className='icon'>
                                                <MdOutlineTimer className='stats-icons' />
                                            </div>

                                            <Heading theme={allStyles}>Time Spent Cubing</Heading>

                                        </div>

                                        <div className='stats-time-spent-content'>

                                            {statsFormatter(totalTimeSpent)}

                                        </div>

                                    </div>

                                </BackgroundBox>

                                {/* total Solves */}

                                <BackgroundBox theme={allStyles} className='stats-box'>

                                    <div className='stats-box-content'>

                                        <div className='stats-box-content-heading'>

                                            <div className='icon'>
                                                <IoIosCube className='stats-icons' />
                                            </div>

                                            <Heading theme={allStyles}>total solves</Heading>

                                        </div>

                                        <div className='stats-total-solve-content'>
                                            {totalSolvesCount}
                                        </div>

                                    </div>

                                </BackgroundBox>

                                {/* days spent */}

                                <BackgroundBox theme={allStyles} className='stats-box-container'>

                                    <div className='stats-box-content'>

                                        <div className='stats-box-content-heading'>

                                            <div className='icon'>
                                                <MdOutlineCalendarMonth className='stats-icons' />
                                            </div>

                                            <Heading theme={allStyles}>days spent cubing</Heading>

                                        </div>

                                        <TextColor theme={allStyles} className='stats-dynamic-box-content'>
                                            {daysSpent.size}
                                        </TextColor>
                                    </div>

                                </BackgroundBox>

                                {/* no. of events */}

                                <BackgroundBox theme={allStyles} className='stats-box-container'>

                                    <div className='stats-box-content'>

                                        <div className='stats-box-content-heading'>

                                            <div className='icon'>
                                                <GoHash className='stats-icons' />
                                            </div>

                                            <Heading theme={allStyles}>number of events</Heading>

                                        </div>

                                        <TextColor theme={allStyles} className='stats-dynamic-box-content'>
                                            {NumberOfEvents}
                                        </TextColor>
                                    </div>

                                </BackgroundBox>

                                {/* Top Event (by time spent) */}

                                <BackgroundBox theme={allStyles} className='stats-box-container'>

                                    <div className='stats-box-content'>

                                        <div className='stats-box-content-heading'>

                                            <div className='icon'>
                                                <LuArrowUpToLine className='stats-icons' />
                                            </div>

                                            <Heading theme={allStyles}>top event (by time spent)</Heading>
                                        </div>

                                        <TextColor theme={allStyles} className='stats-dynamic-box-content'>
                                            {returnMatrixCubeType(highestTimeCountObject)}
                                        </TextColor>
                                    </div>

                                </BackgroundBox>

                                {/* Top Event (by solves) */}

                                <BackgroundBox theme={allStyles} className='stats-box-container'>

                                    <div className='stats-box-content'>

                                        <div className='stats-box-content-heading'>

                                            <div className='icon'>
                                                <LuArrowUpToLine className='stats-icons' />
                                            </div>

                                            <Heading theme={allStyles}>top event (by solves)</Heading>
                                        </div>

                                        <TextColor theme={allStyles} className='stats-dynamic-box-content'>
                                            {returnMatrixCubeType(highestSolvesCountObject)}
                                        </TextColor>
                                    </div>

                                </BackgroundBox>

                            </Background>

                        </div>

                        {/* All Stats Graph */}

                        <div className='stats-event'>

                            <Heading theme={allStyles}>Event Distribution</Heading>

                            <div className='stats-event-container'>

                                {/* Event Distribution By time */}

                                <Background theme={allStyles} className='stats-event-box'>

                                    <TextColor theme={allStyles} className='text-top-right'>
                                        by Time
                                    </TextColor>

                                    {filteredSolvestype?.length === 0 ?
                                        <div className='stats-no-solves-div'>
                                            {/* <Heading theme={allStyles}>No Data...</Heading> */}
                                            <img src={NoSolves} alt="NoSolves" />
                                            <ParaTextColor theme={allStyles}>No solves yet</ParaTextColor>
                                        </div>
                                        :
                                        <PieChart
                                            series={[
                                                {
                                                    arcLabelMinAngle: 30,
                                                    data: resultArrayByTime,
                                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                                    faded: { innerRadius: 30, additionalRadius: -30 },
                                                    valueFormatter: (v) => {

                                                        return `${v.value}%`;
                                                    },
                                                },
                                            ]}
                                            sx={{
                                                [`& .${pieArcLabelClasses.root}`]: {
                                                    fill: 'black',
                                                    fontWeight: '500',
                                                },

                                            }}
                                            slotProps={{
                                                legend: {
                                                    hidden: true
                                                }
                                            }}

                                            width={400}
                                            height={200}
                                            className="label-style"
                                        />

                                    }


                                </Background>

                                {/* Event Distribution By Solves */}

                                <Background theme={allStyles} className='stats-event-box'>

                                    <TextColor theme={allStyles} className='text-top-right'>
                                        by Solves
                                    </TextColor>

                                    {filteredSolvestype?.length === 0 ?
                                        <div className='stats-no-solves-div'>
                                            {/* <Heading theme={allStyles}>No Data...</Heading> */}
                                            <img src={NoSolves} alt="NoSolves" />
                                            <ParaTextColor theme={allStyles}>No solves yet</ParaTextColor>
                                        </div>
                                        :
                                        <PieChart
                                            series={[
                                                {
                                                    arcLabelMinAngle: 30,
                                                    data: resultArrayBySolves,
                                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                                    faded: { innerRadius: 30, additionalRadius: -30 },
                                                    valueFormatter: (v) => {
                                                        return `${v.value}%`;
                                                    },
                                                },
                                            ]}
                                            sx={{
                                                [`& .${pieArcLabelClasses.root}`]: {
                                                    fill: 'black',
                                                    fontWeight: '500',
                                                },

                                            }}
                                            slotProps={{
                                                legend: {
                                                    hidden: true
                                                }
                                            }}

                                            width={400}
                                            height={200}
                                            className="label-style"
                                        />

                                        // <Chart
                                        // chartType="PieChart"
                                        // // data={transformData(resultArrayByTime)}
                                        // data={chartBySolves}
                                        // options={options}
                                        // width={"100%"}
                                        // height={"200px"}
                                        // backgroundColor= {false}
                                        // ></Chart>
                                    }

                                </Background>

                            </div>

                        </div>


                    </>
                }

                {matrix &&
                    <>

                        {/* Matrix Overview  */}

                        <div className='stats-overview'>

                            <Heading theme={allStyles}>Overview</Heading>

                            {!filteredSolvestype || filteredSolvestype?.length === 0
                                ?
                                <Background theme={allStyles} className='session-no-solves-div'>
                                    <img src={NoSolves} alt="NoSolves" />
                                    <ParaTextColor theme={allStyles}>No Solves yet</ParaTextColor>
                                    {/* <Heading theme={allStyles}>No Stats Yet</Heading> */}
                                </Background>
                                :
                                <Background theme={allStyles} className='stats-overview-matrix-content'>


                                    {/* Single PB */}

                                    <BackgroundBox theme={allStyles} className='stats-matrix-box box-1'>

                                        <div className='stats-box-content'>

                                            <div className='stats-box-content-heading'>

                                                <div className='icon'>
                                                    <RiAwardFill className='stats-icons' />
                                                </div>

                                                <Heading theme={allStyles}>Single PB</Heading>

                                            </div>

                                            <TextColor theme={allStyles} className='stats-time-spent-content' style={{ color: '#00CC00' }}>
                                                {stats[returnTextCubeType(cubeType)].pb.time ==
                                                    Number.POSITIVE_INFINITY
                                                    ? "-"
                                                    : formatter(
                                                        stats[returnTextCubeType(cubeType)].pb.time
                                                    )}
                                            </TextColor>

                                        </div>

                                    </BackgroundBox>

                                    {/* Time spent */}

                                    <BackgroundBox theme={allStyles} className='stats-matrix-box-container box-2'>

                                        <div className='stats-box-content'>

                                            <div className='stats-box-content-heading'>

                                                <div className='icon'>
                                                    <MdOutlineTimer className='stats-icons' />
                                                </div>

                                                <Heading theme={allStyles}>time spent cubing</Heading>

                                            </div>

                                            <TextColor theme={allStyles} className='stats-total-solve-content' style={{ fontSize: '20px' }}>
                                                {stats[returnTextCubeType(cubeType)].timeSpent ==
                                                    Number.POSITIVE_INFINITY
                                                    ? "-"
                                                    : statsFormatter(
                                                        stats[returnTextCubeType(cubeType)].timeSpent
                                                    )}
                                            </TextColor>

                                        </div>

                                    </BackgroundBox>

                                    {/* Total Solves */}

                                    <BackgroundBox theme={allStyles} className='stats-matrix-box-container box-3'>

                                        <div className='stats-box-content'>

                                            <div className='stats-box-content-heading'>

                                                <div className='icon'>
                                                    <IoIosCube className='stats-icons' />
                                                </div>

                                                <Heading theme={allStyles}>total solves</Heading>

                                            </div>

                                            <TextColor theme={allStyles} className='stats-dynamic-box-content'>
                                                {stats[returnTextCubeType(cubeType)].solvesCount ==
                                                    Number.POSITIVE_INFINITY
                                                    ? "-"
                                                    :
                                                    stats[returnTextCubeType(cubeType)].solvesCount}
                                            </TextColor>

                                        </div>

                                    </BackgroundBox>

                                    {/* >Ao5 PB */}

                                    <BackgroundBox theme={allStyles} className='stats-matrix-box-container box-4'>

                                        <div className='stats-box-content'>

                                            <div className='stats-box-content-heading'>

                                                <div className='icon'>
                                                    <RiUserHeartLine className='stats-icons' />
                                                </div>

                                                <Heading theme={allStyles}>Ao5 PB</Heading>

                                            </div>

                                            <TextColor theme={allStyles} className='stats-dynamic-box-content'>
                                                {stats[returnTextCubeType(cubeType)].ao5pb.value ==
                                                    Number.POSITIVE_INFINITY
                                                    ? "-"
                                                    : formatter(
                                                        stats[returnTextCubeType(cubeType)].ao5pb.value
                                                    )}
                                            </TextColor>

                                        </div>

                                    </BackgroundBox>

                                    {/* Ao12 PB */}

                                    <BackgroundBox theme={allStyles} className='stats-matrix-box-container box-5'>

                                        <div className='stats-box-content'>

                                            <div className='stats-box-content-heading'>

                                                <div className='icon'>
                                                    <RiUserHeartLine className='stats-icons' />
                                                </div>

                                                <Heading theme={allStyles}>Ao12 PB</Heading>
                                            </div>

                                            <TextColor theme={allStyles} className='stats-dynamic-box-content'>
                                                {stats[returnTextCubeType(cubeType)].ao12pb.value ==
                                                    Number.POSITIVE_INFINITY
                                                    ? "-"
                                                    : formatter(
                                                        stats[returnTextCubeType(cubeType)].ao12pb.value
                                                    )}
                                            </TextColor>

                                        </div>

                                    </BackgroundBox>

                                </Background>
                            }

                        </div>

                        {/* Average */}

                        <div className='stats-average'>

                            <Heading theme={allStyles}>Averages</Heading>

                            <Background theme={allStyles} className='stats-average-box'>

                                {/* avg of 10 */}

                                <BackgroundBox theme={allStyles} className='stats-avg-box-content'>

                                    <TextColor theme={allStyles}>

                                        Average of <span className='avg-number'>10</span>

                                    </TextColor>
                                    <TextColor theme={allStyles}>
                                        {stats[returnTextCubeType(cubeType)]?.solvesCount >= 10
                                            ? formatter(
                                                CalculateAvg(filteredStatsWithoutDNF10)
                                            )
                                            : "-"}
                                    </TextColor>
                                </BackgroundBox>

                                {/* avg of 25 */}

                                <div className='stats-avg-box-content'>
                                    <TextColor theme={allStyles}>

                                        Average of <span className='avg-number'>25</span>

                                    </TextColor>

                                    <TextColor theme={allStyles}>
                                        {stats[returnTextCubeType(cubeType)]?.solvesCount >= 25
                                            ? formatter(
                                                CalculateAvg(filteredStatsWithoutDNF25)
                                            )
                                            : "-"}
                                    </TextColor>

                                </div>

                                {/* avg of 50 */}

                                <BackgroundBox theme={allStyles} className='stats-avg-box-content'>
                                    <TextColor theme={allStyles}>

                                        Average of <span className='avg-number'>50</span>

                                    </TextColor>

                                    <TextColor theme={allStyles}>
                                        {stats[returnTextCubeType(cubeType)]?.solvesCount >= 50
                                            ? formatter(
                                                CalculateAvg(filteredStatsWithoutDNF50)
                                            )
                                            : "-"}
                                    </TextColor>

                                </BackgroundBox>

                                {/* avg of 100 */}

                                <div className='stats-avg-box-content'>
                                    <TextColor theme={allStyles}>

                                        Average of <span className='avg-number'>100</span>

                                    </TextColor>

                                    <TextColor theme={allStyles}>
                                        {stats[returnTextCubeType(cubeType)]?.solvesCount >= 100
                                            ? formatter(
                                                CalculateAvg(filteredStatsWithoutDNF100)
                                            )
                                            : "-"}
                                    </TextColor>

                                </div>

                                {/* avg of 500 */}

                                <BackgroundBox theme={allStyles} className='stats-avg-box-content'>
                                    <TextColor theme={allStyles}>

                                        Average of <span className='avg-number'>500</span>

                                    </TextColor>

                                    <TextColor theme={allStyles}>
                                        {stats[returnTextCubeType(cubeType)]?.solvesCount >= 500
                                            ? formatter(
                                                CalculateAvg(filteredStatsWithoutDNF500)
                                            )
                                            : "-"}
                                    </TextColor>
                                </BackgroundBox>

                                {/* avg of 1000 */}

                                <div className='stats-avg-box-content'>
                                    <TextColor theme={allStyles}>

                                        Average of <span className='avg-number'>1000</span>

                                    </TextColor>

                                    <TextColor theme={allStyles}>
                                        {stats[returnTextCubeType(cubeType)]?.solvesCount >= 1000
                                            ? formatter(
                                                CalculateAvg(filteredStatsWithoutDNF1000)
                                            )
                                            : "-"}
                                    </TextColor>
                                </div>

                            </Background>

                        </div>
                    </>
                }

            </div>

        </div>
    )
}

export default StatsComponent