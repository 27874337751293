import React, { useContext, useRef, useState, useMemo } from 'react'
import { Heading, ProfileModal, FocusBox, TextColor } from './styledComponent.js'
import { IoMdClose } from 'react-icons/io'
import './ImportModal.scss'
import { ThemeContext } from '../../../context.js'
import { ReserveContext } from '../../../App.jsx'
import { useNavigate } from 'react-router-dom'
import { submitBulkSolve } from '../../../apis/bulkSolves.js'
import { getAllSessions } from '../../../apis/sessions.js'
import { getAllSolves } from '../../../apis/solves.js'
import { toast } from 'react-toastify'

const ImportModalUpcube = () => {
  const updateReserve = useContext(ReserveContext).updateReserve;

  const { allStyles,themeName } = useContext(ThemeContext);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const[numberOfSolves,setNumberOfSolves] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const {reserve : {sessions =[]}} = useContext(ReserveContext);
  const inputRef = useRef(null);
  
  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles(files);
  };

  const [importData, setimportData] = useState([]);

  const existingSessionNames = useMemo(() => {
      const sessionNames = sessions.map(session => session.name);
      return sessionNames;
    }, [sessions]);
  
    const sessionNameExists = (sessionName) => {
      // setError(error => error + 1)
      return existingSessionNames.includes(sessionName);
    }
  
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const content = e.target.result;
      // let rows = content.split('\n');

      const lines = content.split('\n');
      let rows = [];
      let currentRow = '';
      let isMinxRow = false;

      // Separate the rows - different for minx cubetype
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();

        // Check if the line starts with a date (indicating a new row)
        if (line.match(/^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4}/)) {
          // If the current row is not empty, push it to the rows array
          if (currentRow !== '') {
            rows.push(currentRow);
          }
          // Start a new row
          currentRow = line;
          // Check if this row is for the `minx` cube type
          isMinxRow = line.includes(',minx,');
        } else {
          // If it's a `minx` row, append the line to the current row
          if (isMinxRow) {
            currentRow += ' ' + line;
          } else {
            // For non-minx rows, treat each line as a separate row
            if (currentRow !== '') {
              rows.push(currentRow);
            }
            currentRow = line;
          }
        }
      }

      // Push the last row if it exists
      if (currentRow !== '') {
        rows.push(currentRow);
      }

      // Now `rows` contains the correctly separated rows

      // rows = rows.map(entry => entry.replace(/\r/g, "").replace(/"/g, ""));
      // const data = rows.map(row => row.split(','));
      
      
      // splitting each row with comma -  different for sq1 case - using regex
      const data = rows.map(row => {
          let newRow = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          newRow[3] =  newRow[3].replace(/\r/g, "").replace(/"/g, "").replace(/^"|"$/g, '');
          if(sessionNameExists(newRow[4])) {
            newRow[4] = newRow[4] + "-2";
          }
          return [...newRow];
      });
  
      const updatedData = data.map(row => {
        const updatedRow = [...row];
        updatedRow[5] = row[5] === "true"; 
        updatedRow[6] = row[6] === "true"; 
        return updatedRow;
      });
      // console.log("updatedData",updatedData);
      setNumberOfSolves(updatedData.length-1);
      // Create JSON object including all properties and 'solves' object within each sessionName
      const updatedJsonObject = [];
      updatedData.forEach((row,i) => {
        if(i!==0 && row[4])
          { 
            const sessionName = row[4]; 
            const solve = {
              time: parseInt(row[1]*1000),
              solveId: " ",
              dnfAuto: false,
              dnfManual: row[5] , 
              plus2Auto: false,
              plus2Manual: row[6] , 
              cubeType: row[2], 
              scramble: row[3],
              notes: " ",
              uid: "",
              sessionId:" ",
              dateTime: row[0], 
            };
      
            const sessionIndex = updatedJsonObject.findIndex(session => session.sessionName && (session.sessionName === sessionName));
            if (sessionIndex === -1) {
              updatedJsonObject.push({ sessionName, solves: [solve] });
            } else {
              updatedJsonObject[sessionIndex].solves.push(solve);
            }}
      });
      
      setimportData(updatedJsonObject);
      // console.log(updatedJsonObject);
    };
  
    reader.readAsText(file);
  };

  console.log(importData);

  const navigate = useNavigate();

const handleImport = async (importData) => {
  try {
    setButtonClicked(true);

    const response = await submitBulkSolve(importData);
    // console.log(response);
    let allSession = await getAllSessions();
    // console.log(allSession);
    let allSolves= await getAllSolves();
    // console.log(allSolves);
    updateReserve((prevReserve) => ({
      ...prevReserve,
      sessions:allSession?.sessions,
      solves:allSolves?.solves,
  }));
    navigate("/sessions");
    toast.success("Solves imported successfully.",{autoClose:1000,className:'copy-toast'})
  } catch (err) {
    toast.error("Import Failed, Please try again.",{autoClose:1000,className:'copy-toast'})
  }
}

  const {
        setimportModalUpcube,
  } = useContext(ReserveContext)

  return (
    <div className="profile-modal" style={{overflow: 'hidden'}}>
     <ProfileModal theme={allStyles} className='profile-modal-container' style={{
        overflowY: 'scroll',
        maxHeight: '80vh',
      }}>

        {/* close button */}
        <div className="import-modal-close-button" style={{ position: 'relative', zIndex: '1000' }} onClick={() => { setimportModalUpcube(false) }}>

          <Heading theme={allStyles} className='import-modal-heading'>
            Import data from UpCube
          </Heading>

          <div
            aria-label="Close" style={{ top: '18px' }}
          >
            <IoMdClose style={{color: themeName === 'light' ? 'black' : 'white'}} />
          </div>

        </div>

        {/* main heading */}
        <div>

          <Heading theme={allStyles} className='import-modal-sub-heading'>
            How to export data from UpCube
          </Heading>

          {/* Modal Steps */}
          <div className='import-modal-steps'>

            <div>
              <TextColor theme={allStyles} className='import-modal-step'>
                1. Navigate to <a href="/settings" className={`import-modal-link ${themeName==='light'?"text-color-light":""}`}>Settings</a> page
              </TextColor>
            </div>

            <div>
              <TextColor theme={allStyles} className='import-modal-step'>
                2. Click "Export all data" and save the file
              </TextColor>
            </div>

          </div>

        </div>

        {/* horizontal Line */}

        <hr className='import-modal-horizontal-line' />

        {/* select file to import */}
        <div>

          <Heading theme={allStyles} className='import-modal-select-file'>
            Select the file to import
          </Heading>

          <div
            className="file-uploader"
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
            onClick={() => inputRef.current.click()}
          >
            <input
              type="file"
              style={{ display: 'none' }}
              accept="text/csv"
              onChange={handleFileUpload}
              ref={inputRef}
            />
            <TextColor theme={allStyles}>Drag and drop files here or click to select</TextColor>

          </div>
        </div>
        {/* horizontal Line */}

        <div className='import-modal-horizontal-line' style={{bottom: '-3.5rem'}} />

        

        {importData.length !== 0 && <div style={{marginTop: '2rem'}}>

          <TextColor theme={allStyles} className='import-modal-sub-heading'>
            Review & import
          </TextColor>

          <TextColor theme={allStyles} className='import-modal-step' >
            Please make sure that the number below look correct. Then click Import data!
          </TextColor>

        </div>}

        {/* Sessions and solves */}

        {importData.length !== 0 && <div className='import-modal-solves-sessions-data'>

          <TextColor theme={allStyles} className='import-modal-solve-value'>
            Solves : <span style={{ color: '#41b058' }}>{numberOfSolves}</span>
          </TextColor>

          <TextColor theme={allStyles} className='import-modal-session-value'>
            Sessions : <span style={{ color: '#41b058' }}>{importData.length}</span>
          </TextColor>

          <div className='import-modal-horizontal-line' style={{bottom: '-3.5rem'}} />

        </div>
}
        {/* horizontal Line */}

        <hr className='import-modal-horizontal-line' />

        {/* imported Data */}

        {importData.length !== 0 &&
          <div>

            {importData.length === 0 && (
              <Heading theme={allStyles} className='import-modal-no-data'>
                No data to import
              </Heading>
            )}
          </div>
}

        {/* Import button */}
        {importData.length !== 0 && (
        <FocusBox
          theme={allStyles}
          className={`focus-button import-modal-btn ${buttonClicked ? 'button-disabled' : ''}`}
          style={{ padding: '12px 5px', pointerEvents: buttonClicked ? 'none' : 'auto' }}
          onClick={() => handleImport(importData)}
        >
          Import data
        </FocusBox>
      )}

      </ProfileModal>
    </div>
  )
}

export default ImportModalUpcube