import React, { useContext, useState, useRef, useEffect } from 'react'
import "./TopBar.scss"
import Ham from '../../../assets/images/ham.png'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg';
import lightHlogo from '../../../assets/images/logos/Logo_monotone_lightbgd_H.png';
import { ThemeContext } from '../../../context'
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MainNavDiv, NavTextDiv } from "../styledComponents";
import profileNav from '../../../assets/images/nav/profile.png'
import Timer from "../../../assets/images/nav-svg/timer.svg";
import Stats from "../../../assets/images/nav-svg/stats.svg";
import Solves from "../../../assets/images/nav-svg/solves.svg";
import Sessions from "../../../assets/images/nav-svg/sessions.svg";
import Glossary from "../../../assets/images/nav-svg/glossary.svg";
import Learning from "../../../assets/images/nav-svg/learning.svg";
import Help from "../../../assets/images/nav-svg/help.svg";
import Settings from "../../../assets/images/nav-svg/settings.svg";
import lightLogo from "../../../assets/images/Logo_color_logo.svg";
import TimerMob from "../../../assets/images/nav-svg/timerMob.svg";
import StatsMob from "../../../assets/images/nav-svg/statsMob.svg";
import SolvesMob from "../../../assets/images/nav-svg/solvesMob.svg";
import SessionsMob from "../../../assets/images/nav-svg/sessionMob.svg";
import GlossaryMob from "../../../assets/images/nav-svg/glossaryMob.svg";
import LearningMob from "../../../assets/images/nav-svg/learningMob.svg";
import HelpMob from "../../../assets/images/nav-svg/helpMob.svg";
import SettingsMob from "../../../assets/images/nav-svg/settingMob.svg";
import ProfileMob from '../../../assets/images/nav/profileMob.png'
import { ReserveContext } from '../../../App'
import { useIsMobile } from '../../../utils/functions/CustomHooks'
import { toast } from 'react-toastify';

const MobileTop = () => {
  const [isHmOpen, setisHmOpen] = useState(false)
  const router = useNavigate()
  const { isFocus, overlay, Setoverlay, footerBlip, setFooterBlip } = useContext(ReserveContext)
  const location = useLocation();
  const { themeName, allStyles } =
    useContext(ThemeContext);
  const isMobile = useIsMobile()
  const sidebarRef = useRef(null);
  const navRef = useRef(null);
  const handleSidebar = () => {
    // if (isOpen === false) {
    //   setisHmOpen(true)
    //   document.body.style.overflow = 'hidden';
    // } else {
    //   setisHmOpen(false)
    //   document.body.style.overflow = 'auto';
    // }
    setisHmOpen(!isHmOpen)
  }
  const handleCloseSidebar = () => {
    setisHmOpen(false)
  }

  useEffect(() => {
    // Handle clicks outside the sidebar
    const handleOutsideClick = (e) => {
      if (sidebarRef.current && !sidebarRef.current.contains(e.target) && e.target.id !== "ham-mobile") {
        handleCloseSidebar()

      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleOutsideClick);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isHmOpen]);

  const handleClick = () => {
    Setoverlay(false)
    // if (!footerBlip) {
    //   setFooterBlip(true);
    //   toast.error('Close the footer to start the Timer', { autoClose: 1000, className: "copy-toast" });
    //   setTimeout(() => {
    //     setFooterBlip(false); // Enable again after 5 seconds
    //   }, 2000);
    // }
  };

  return (
    <>
      {overlay ? <div className="mobile-top-overlay" onClick={handleClick}></div> : null}

        <div className="mobile-top-bar" style={{ display: `${isMobile && isFocus ? 'none' : ''}`, position: "fixed", width: "100%", background: `${allStyles.backgroundColor}`, zIndex: '1'}} >

        {!isFocus && <div className="mobile-ham" 
        // style={{ position: 'relative', zIndex: '999' }} 
        onClick={handleSidebar}>
          <img src={Ham} alt="" ref={navRef} id='ham-mobile' />
        </div>}

        {!isFocus && <div className="mobile-top-logo" onClick={() => { router('/timer') }}>
          {themeName === 'default' || themeName === 'dark' ? <Logo alt="Logo" /> : <img src={lightHlogo} alt="Logo" />
          }
        </div>}

        {/* <div className="mobile-profile"><RoundBorder src={Profile} alt="" /></div> */}

        {isHmOpen && <div className='black-bg-container'></div>}

        
      </div>
      <div>
          <MainNavDiv
          theme={allStyles}
          className={`nav`}
          style={{ transform: isHmOpen ? 'translateX(0px)' : 'translateX(-155px)' }}
          ref={sidebarRef}
        >
          
          {/* Top nav links */}
          <div className="top" style={{zIndex: '2'}}>

            <div className={`nav-top-menu`}>

              <div className={`nav-main-item-box`}>

                {/* Timer */}

                <NavLink activeClassName="active" style={{ textDecoration: 'none' }} to="/timer" className={`nav-item`} onClick={() => { setisHmOpen(false) }}>

                  <div className="nav-icon">
                    {themeName === 'default' || themeName === 'dark' ? <img src={Timer} alt="Timer" /> : <img src={TimerMob} alt="" />}
                  </div>

                  <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/timer' ? "true" : "false"}>
                    Timer
                  </NavTextDiv>

                </NavLink>

                {/* Stats */}

                <NavLink activeclassname="active" style={{ textDecoration: 'none' }} to="/stats" className={`nav-item `} onClick={() => { setisHmOpen(false) }}>
                  <div className="nav-icon">
                    {themeName === 'default' || themeName === 'dark' ? <img src={Stats} alt="Stats" /> : <img src={StatsMob} alt="" />}
                  </div>

                  <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/stats' ? "true" : "false"}>
                    Stats
                  </NavTextDiv>
                </NavLink>

                {/* Solves */}

                <NavLink activeclassname="active" style={{ textDecoration: 'none' }} to="/solves" className={`nav-item`} onClick={() => { setisHmOpen(false) }}>
                  <div className="nav-icon">
                    {themeName === 'default' || themeName === 'dark' ? <img src={Solves} alt="Solves" /> : <img src={SolvesMob} alt="" />}
                  </div>
                  <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/solves' ? "true" : "false"}>
                    Solves
                  </NavTextDiv>
                </NavLink>

                {/* Sessions */}

                <NavLink activeclassname="active" style={{ textDecoration: 'none' }} to="/sessions" className={`nav-item`} onClick={() => { setisHmOpen(false) }}>
                  <div className="nav-icon">
                    {themeName === 'default' || themeName === 'dark' ? <img src={Sessions} alt="Sessions" /> : <img src={SessionsMob} alt="" />}

                  </div>

                  <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/sessions' ? "true" : "false"}>
                    Sessions
                  </NavTextDiv>
                </NavLink>

                {/* Glossary */}

                <NavLink activeclassname="active" style={{ textDecoration: 'none' }} to="/glossary" className={`nav-item`} onClick={() => { setisHmOpen(false) }}>
                  <div className="nav-icon">
                    {themeName === 'default' || themeName === 'dark' ? <img src={Glossary} alt="Glossary" /> : <img src={GlossaryMob} alt="" />}
                  </div>

                  <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/glossary' ? "true" : "false"}>
                    Glossary
                  </NavTextDiv>
                </NavLink>

                {/* Learning */}

                <NavLink activeclassname="active" style={{ textDecoration: 'none' }} to="/learnings" className={`nav-item`} onClick={() => { setisHmOpen(false) }}>
                  <div className="nav-icon">
                    {themeName === 'default' || themeName === 'dark'
                      ?
                      <img src={Learning} alt="Learning" />
                      :
                      <img src={LearningMob} alt="" />
                    }
                  </div>

                  <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/glossary' ? "true" : "false"}>
                    Learning
                  </NavTextDiv>
                </NavLink>

                {/* Profile */}
              <NavLink activeclassname='active' style={{
                textDecoration: 'none',
              }}
                to="/profile" className={`nav-item`} onClick={() => { setisHmOpen(false) }}>

                <div className="nav-icon">

                  {themeName === 'default' || themeName === 'dark'
                    ?
                    <img src={profileNav} alt="Profile" />
                    :
                    <img src={ProfileMob} alt="" />}

                </div>

                <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/help' ? "true" : "false"}>
                  Profile
                </NavTextDiv>

              </NavLink>

              {/* Help */}

              <NavLink activeclassname={`active`} style={{ textDecoration: 'none' }} to="/help" className={`nav-item`} onClick={() => { setisHmOpen(false) }}>

                <div className="nav-icon">
                  {themeName === 'default' || themeName === 'dark' ? <img src={Help} alt="Help" /> : <img src={HelpMob} alt="" />}
                </div>

                <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/help' ? "true" : "false"}>
                  Help
                </NavTextDiv>
              </NavLink>

              {/* Settings */}

              <NavLink activeclassname="active" style={{ textDecoration: 'none' }} to="/settings" className={`nav-item`} onClick={() => { setisHmOpen(false) }}>

                <div className="nav-icon">
                  {themeName === 'default' || themeName === 'dark' ? <img src={Settings} alt="Settings" /> : <img src={SettingsMob} alt="" />}
                </div>

                <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/settings' ? "true" : "false"}>
                  Settings
                </NavTextDiv>
              </NavLink>

              </div>

            </div>

          </div>

        </MainNavDiv>
        </div>
    </>
  )
}

export default MobileTop