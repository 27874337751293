import React from "react";
import { useState } from "react";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { signInWithPopup, GoogleAuthProvider,signInWithRedirect } from "firebase/auth";
import { sendLink } from "../../utils/firebase/emailSignIn";
import { useNavigate } from "react-router-dom";
import { handleRedirect } from "../../utils/firebase/emailSignIn";
import "./Auth.scss";
import AuthComponenet from "../../components/Auth/auth/auth";
import Right from "../../components/Auth/Right/Right.tsx";

const Auth = ({navigate, setguide}) => {
  return (
    <>
      <AuthComponenet GoogleAuthProvider={GoogleAuthProvider} signInWithPopup={signInWithPopup} signInWithRedirect={signInWithRedirect} setguide={setguide} />
    </>
  );
};

export default Auth;
