import React, { useContext } from 'react'
import { Box } from '../styledComponents'
import ScrambledCube from '../../ScrambledCube/ScrambledCube'
import { ReserveContext } from '../../../App'

const ScrambleBox = ({allStyles,svg,scramble,cubeType, Svg,load}) => {
  const { isFocus } = useContext(ReserveContext);

  return (
  <>
  
  {load ? (
     <Box theme={allStyles}  boxType="scramble" className={`timer-box timer-box-3 ${isFocus ? 'hidden' : ''}`} 
      
     >
     {svg === "load" ? (
       <>Loading...</>
     ) : svg === "cube" ? (
       <ScrambledCube scramble={scramble} cubeType={cubeType} />
     ) : (
       <Svg cubeType={cubeType} scramble={scramble} />
     )}
   </Box>
   ) : (
     <Box theme={allStyles}  className={`timer-box timer-box-3 ${isFocus ? 'hidden' : ''}`}
     ></Box>
   )}

  </>
 
  )
}

export default ScrambleBox