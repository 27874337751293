import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../context';
import './ProfileModal.scss'
import { ProfileModal, FocusBox, InputTextColor } from './styledComponent.js'
import { toast } from 'react-toastify';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
    CitySelect,
    CountrySelect,
    StateSelect,
    GetCountries,
    GetState,
    GetCity,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import axios from 'axios';
import { FaCalendarAlt } from "react-icons/fa";
import { IoMdClose } from 'react-icons/io'
import { ReserveContext } from '../../../App.jsx';
import animation from "../../../assets/images/animation_3.gif"
import { useIsMobile } from '../../../utils/functions/CustomHooks.js';
const ProfileMobileModal = ({formData, setFormData}) => {

    const { allStyles, themeName } = useContext(ThemeContext);

    const isMobile = useIsMobile();
    const { setProfileMobile } = useContext(ReserveContext);

    const [userData, setUserData] = useState(null);
    // const [formData, setFormData] = useState({
    //     name: '',
    //     gender: '',
    //     wcaid: '',
    //     DOB: '',
    //     country: null,
    //     state: null,
    //     city: null,
    //     mobile: '',
    // });
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }, []);

    const handleDateChange = useCallback((date) => {
        setFormData(prev => ({ ...prev, DOB: date }));
    }, []);

    const handleCountryChange = useCallback((country) => {
        setFormData(prev => ({
            ...prev,
            country,
            state: null,
            city: null,
        }));
    }, []);

    const handleStateChange = useCallback((state) => {
        setFormData(prev => ({
            ...prev,
            state,
            city: null,
        }));
    }, []);

    const handleCityChange = useCallback((city) => {
        setFormData(prev => ({ ...prev, city }));
    }, []);

    const fetchUserData = useCallback(async () => {
        try {
            const response = await axios.get('https://upcube.el.r.appspot.com/api/v1/auth/user');
            localStorage.setItem("profile", false);

            if (!response) {
                toast.error('Failed to fetch user data.');
                return;
            }

            const { user } = response.data;
            setUserData(user);

            const countryList = await GetCountries();
            const selectedCountry = countryList.find(item => item.name === (user.country === 'country' ? "" : user.country)) || null;
            let selectedState = null;
            let selectedCity = null;

            if (selectedCountry) {
                const stateList = await GetState(selectedCountry.id);
                selectedState = stateList.find(item => item.name === user.state) || null;

                if (selectedState) {
                    const cityList = await GetCity(selectedCountry.id, selectedState.id);
                    selectedCity = cityList.find(item => item.name === user.city) || null;
                }
            }

            setFormData({
                name: user.name || '',
                gender: user.gender !== 'other' ? user.gender : '',
                wcaid: user.social.wca || '',
                DOB: user.dob === '1989-12-31T18:30:00.000Z' ? "" : new Date(user.dob),
                country: selectedCountry,
                state: selectedState,
                city: selectedCity,
                mobile: user.mobile !== '1234567890' ? user.mobile : '',
            });

        } catch (error) {
            console.error('Error fetching user data:', error);
            toast.error('Failed to fetch user data.');
        }
    }, [formSubmitted]);

     // this useEffect is to scroll to the top of the page for ios, ios input box focus bug
      useEffect(() => {
          window.scrollTo(0, 0);
      })

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData, formSubmitted]);

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (!formData.country) {
            toast.error('Please select yor Country from dropdown');
            return;
        }
        if (!formData.state) {
            toast.error('Please select proper State & City');
            return;
        }
        if (!formData.city) {
            toast.error("Please select proper City");
            return;
        }

        const updatedUserData = {
            ...userData,
            ...formData,
            country: formData.country?.name || '',
            state: formData.state?.name || '',
            city: formData.city?.name || '',
            social: {
                ...userData.social,
                wca: formData.wcaid
            }
        };

        try {
            const response = await axios.put('https://upcube.el.r.appspot.com/api/v1/auth/update', updatedUserData);
            if (response) {
                toast.success('Saved changes successfully');
                setFormSubmitted(true);
                setTimeout(() => setFormSubmitted(false), 3000);
                setProfileMobile(false);
                setUserData(updatedUserData);
            } else {
                toast.error('Failed to save changes.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Failed to Save Changes.');
        }
    };

    if (!userData) {
        return (
            <div className='loader-mobile' style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100vw',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}>
                <img src={animation} alt="Loading.." />
            </div>
        );
    }

    // console.log(formData)


    return (
        <>
            {
                userData ? (<div className="profile-modal" style={{ overflowY: 'auto', paddingTop: "1rem" }}>

                    <ProfileModal theme={allStyles} className='profile-modal-container' style={{ padding: '2rem 1rem', height: '80vh' }}>

                        {/* close button */}
                        {!isMobile ? (<div style={{ position: 'relative' }} onClick={() => setProfileMobile(false)}>
                            <button
                                className="close-button" aria-label="Close"
                                style={{ right: '-5px', backgroundColor: themeName === 'light' ? 'rgb(180 180 180)' : 'null' }}
                            >
                                <IoMdClose style={{ width: '20px' }} />
                            </button>
                        </div>) : (<div style={{ position: 'relative' }} onClick={() => setProfileMobile(false)}>
                            <button
                                className="close-button" aria-label="Close"
                                style={{ right: '-5px', top: '-28px', backgroundColor: themeName === 'light' ? 'rgb(180 180 180)' : 'null' }}
                            >
                                <IoMdClose style={{ width: '20px' }} />
                            </button>
                        </div>)}

                        <div className='profile-form-modal'>

                            <div className=''>

                                <form onSubmit={handleSubmit}>
                                    <div className='profile-form-content'>
                                        {/* Name */}
                                        <div className='form-div'>
                                            <InputTextColor theme={allStyles} htmlFor="name">Name</InputTextColor>
                                            <input
                                                type="text"
                                                value={formData.name}
                                                onChange={handleChange}
                                                name='name'
                                                id='name'
                                                required
                                            />
                                        </div>

                                        {/* Gender */}
                                        <div className='form-div'>
                                            <InputTextColor theme={allStyles} htmlFor="gender">Gender</InputTextColor>
                                            <select
                                                value={formData.gender}
                                                onChange={handleChange}
                                                name='gender'
                                                id='gender'
                                                required
                                            >
                                                <option value="">Select</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>

                                        {/* WCA id */}
                                        <div className='form-div'>
                                            <InputTextColor theme={allStyles} htmlFor="wcaid">WCA id</InputTextColor>
                                            <input
                                                type="text"
                                                value={formData.wcaid}
                                                onChange={handleChange}
                                                name='wcaid'
                                                id='wcaid'
                                                placeholder='Enter WCA id'
                                            />
                                        </div>

                                        {/* Date of Birth */}
                                        <div className='form-div' style={{ position: 'relative' }}>
                                            <InputTextColor theme={allStyles} htmlFor="DOB">Date of Birth</InputTextColor>
                                            <FaCalendarAlt className='info-profile-calendar' />
                                            <Datetime
                                                value={formData.DOB}
                                                onChange={handleDateChange}
                                                timeFormat={false}
                                                dateFormat="DD-MM-YYYY"
                                                placeholderText="Select a date"
                                                inputProps={{ placeholder: "Select a date" }}
                                                className={`profile-date-picker${themeName === 'light' ? '' : ''}`}
                                            />
                                        </div>

                                        {/* Country */}
                                        <div className='form-div'>
                                            <InputTextColor theme={allStyles} htmlFor="country">Country</InputTextColor>
                                            <CountrySelect
                                                value={formData.country}
                                                defaultValue={formData.country}
                                                placeHolder='Country'
                                                onChange={handleCountryChange}
                                                className="profile-country-dropdown"
                                                required
                                            />
                                        </div>

                                        {/* State */}
                                        <div className='form-div'>
                                            <InputTextColor theme={allStyles} htmlFor="state">State</InputTextColor>
                                            <StateSelect
                                                value={formData.state}
                                                defaultValue={formData.state}
                                                countryid={formData.country?.id}
                                                placeHolder='State'
                                                onChange={handleStateChange}
                                                className="profile-state-dropdown"
                                                required
                                            />
                                        </div>

                                        {/* City */}
                                        <div className='form-div'>
                                            <InputTextColor theme={allStyles} htmlFor="city">City</InputTextColor>
                                            <CitySelect
                                                key={formData.state?.id}
                                                defaultValue={formData.city}
                                                value={formData.city}
                                                countryid={formData.country?.id}
                                                stateid={formData.state?.id}
                                                placeHolder="City"
                                                onChange={handleCityChange}
                                                className="profile-state-dropdown"
                                                required
                                            />
                                        </div>

                                        {/* Mobile */}
                                        <div className='form-div'>
                                            <InputTextColor theme={allStyles} htmlFor="mobile">Mobile Number</InputTextColor>
                                            <input
                                                type="number"
                                                value={formData.mobile}
                                                onChange={handleChange}
                                                name='mobile'
                                                id='mobile'
                                                placeholder='Enter mobile no.'
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className='profile-form-btn'>
                                        <FocusBox
                                            theme={allStyles}
                                            className='focus-button focus-btn-desktop'
                                            type='submit'
                                            // style={{
                                            //     cursor: formSubmitted && 'not-allowed',
                                            // }}
                                            disabled={formSubmitted}
                                        >
                                            <botton onClick={handleSubmit}>Update Profile</botton>
                                        </FocusBox>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </ProfileModal>

                </div>) : (<div className='loader-mobile' style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100vw',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}>
                    <img src={animation} alt="Loading.." />
                </div>)
            }
        </>
    )
}

export default ProfileMobileModal