import React, { useContext } from 'react'
import { FocusBox, Heading, ProfileModal } from './StyledComponent'
import { IoMdClose } from 'react-icons/io'
import { ThemeContext } from '../../context';

const SessionDeleteModal = ({ allStyles, setSessionModal, sessionid, handleDeleteSession }) => {
  const { themeName } = useContext(ThemeContext);
  return (
    <div className='profile-modal'>

      <ProfileModal theme={allStyles} className='profile-modal-container delete-modal-width'>

        <div style={{ position: 'relative' }} onClick={() => { setSessionModal(false) }}>
          <button
            className="close-button" aria-label="Close"
            style={{ right: '-5px', backgroundColor: themeName === 'light' ? 'rgb(180 180 180)' : 'null' }}
          >
            <IoMdClose style={{ width: '20px' }} />
          </button>
        </div>

        {/* heading */}
        <Heading theme={allStyles} className='profile-edit-heading' style={{ textAlign: 'center' }}>Want to Delete a Session ?</Heading>

        <div className='btn-container'>

          <FocusBox theme={allStyles} className='focus-button' type='submit' style={{ marginTop: '1rem' }} onClick={() => {
            handleDeleteSession(sessionid)
            setSessionModal(false)
          }}
          >
            Confirm Delete
          </FocusBox>

        </div>

      </ProfileModal>
    </div>
  )
}

export default SessionDeleteModal