import React, { useContext, useEffect, useState } from "react";
import { OptionDiv, ButtonInactive, FocusBox, SelectDiv } from "../styledComponent";
import { ThemeContext } from "../../../context";
import { useRef } from "react";

const Dropdown = ({
  ref,
  options,
  selectedOption,
  onOptionClick,
  allStyles,
  session,
  handleDDSession,
  dispCNS,
  isOpen,
  setIsOpen,
  sessionId,
  setSessionId,
  
}) => {
  const [dropDownOptions, setdropDownOptions] = useState(options);
  const { themeName, setThemeName } = useContext(ThemeContext)
  const dropdownRef = useRef()
  useEffect(() => {
    // Handle clicks outside the sidebar
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target))  {
        // setIsOpen(!isOpen)
        // setDropdownClicked(!dropdownClicked)
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleOutsideClick);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  useEffect(() => {
    let newOptions= [...options.filter((item) => item.sessionId === selectedOption.sessionId), ...options.filter((item) => item.sessionId !== selectedOption.sessionId)]
    setdropDownOptions(newOptions);
  }, [onOptionClick, selectedOption])


  return (
    <div className="dropdown" ref={dropdownRef}
    id={`${themeName ==='light' ?'style-1' :""}`}

    >

      {session ? (
        <OptionDiv
          onClick={() => {
            handleDDSession(true);
            setIsOpen(false);
          }}
          theme={allStyles}
          className="CNS-drop-create-button"
          
        >
          <FocusBox theme={allStyles} style={{background: `${themeName === 'default' ? '#692bcb' : ''}`}} className="DD-in">Create Session +</FocusBox>
        </OptionDiv>
      ) : null}


      {options ? <SelectDiv theme={allStyles}
         className="options">
        {dropDownOptions.map((option) => (
          <div
            key={option.sessionId}
            value={sessionId}
            className={`option ${option.name === selectedOption.name && themeName === 'default' ? "selected-default" : ""}
            ${option.name === selectedOption.name && themeName === 'dark' ? "selected-dark" : ""}
            ${option.name === selectedOption.name && themeName === 'light' ? "selected-light" : ""}
            ${option.name !== selectedOption.name && themeName === 'light' && "font-black"}
            `}
            onClick={() => {
              onOptionClick(option);
              setSessionId(option.value)
              setIsOpen(false)
            }}
          >
            <span style={{color: `${option.name === selectedOption.name && themeName === 'light' ? 'purple' : ''}`, fontSize: "1rem"}} >{option.name}</span>
            {option.name === selectedOption.name && <svg xmlns="http://www.w3.org/2000/svg" viewBox="-49 141 512 512" width="16" height="16" aria-hidden="true" className="dropdown-tick" fill={themeName==='light'?"#00000":"#FFFFFF"}><path d="M127 552.25a24.92 24.92 0 0 1-17.678-7.322l-101-101c-9.763-9.763-9.763-25.593 0-35.355 9.764-9.764 25.592-9.764 35.355 0l83.339 83.34 243.323-242.857c9.771-9.754 25.602-9.74 35.355.033 9.754 9.772 9.739 25.602-.033 35.355l-261 260.5A24.92 24.92 0 0 1 127 552.25"></path></svg>}
          </div>
        ))}
      </SelectDiv> : null}


    </div>
  );
};

export default Dropdown;
